import { Box, Paper, Typography, Stack, IconButton, Avatar, Tooltip } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import UpdateStepStatusPopup from "./updateStatusPopup";
import swal from "sweetalert2";
import axios from "axios";
import gravatar from 'gravatar';

const StatusCard = ({ stepData, index, idToken, loginData, getStatusAPI }) => {
  const [isUpdatePopupOpen, setUpdatePopupOpen] = useState(false);

  const [avatars, setAvatars] = useState([]);

  useEffect(() => {
    const avatarData = stepData?.dependencyArray?.map((email) => {
      const avatarUrl = gravatar.url(email, { s: '200', r: 'pg', d: 'identicon' });
      return { src: avatarUrl, alt: email };
    });

    setAvatars(avatarData);
  }, []);

  console.log(avatars)

  const handleUpdatePopupOpen = () => {
    setUpdatePopupOpen(true);
  };

  const handleUpdatePopupClose = () => {
    setUpdatePopupOpen(false);
  };

  var currentstep = false;
  if (stepData?.status === "inProgress") {
    currentstep = true;
  }


  const onClickDeleteExperience = (stepId) => {
    const swalInstance = new swal({
      title: "Are you sure?",
      text: `Once deleted, you will not be able to recover this step!`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    });

    swalInstance.then((willDelete) => {
      if (willDelete) {
        handleDeleteStep(stepId);
      }
    });
  };

  function trimStringAtThirdSpace(inputString) {
    const words = inputString.split(' ');
    if (words.length >= 3) {
      return words.slice(0, 3).join(' ') + "...";
    } else {
      return inputString;
    }
  }
  
  function calculateTimeRemaining(deadline, completedAt) {
    const now = new Date();
    const targetDate = new Date(deadline);
  
    if (isNaN(targetDate)) {
      return "Invalid deadline format";
    }
  
    const timeDifference = targetDate - now;
  
    if (completedAt) {
      const completedDate = new Date(completedAt);
      if (!isNaN(completedDate) && completedDate <= now) {
        const extraTimeDifference = now - completedDate;
        const extraDays = Math.floor(extraTimeDifference / (1000 * 60 * 60 * 24));
        const extraHours = Math.floor((extraTimeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        return `${extraDays} extra days ${extraHours} extra hours used after the deadline`;
      }
    }
  
    if (timeDifference <= 0) {
      return "Deadline has passed";
    }
  
    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  
    return days < 1 ?`⚠ ${days} days ${hours} hours left to finish this step!` : "";
  }
  

  async function handleDeleteStep(stepId) {
    try {
      axios.defaults.headers.common["Authorization"] = idToken;
      const response = await axios.delete(
        `https://api.niroggyan.com/onboarding/status/deletestep?id=${stepId}`
      );

      response.status === 200 &&
        swal.fire({
          icon: "success",
          text: "Step deleted Successfully",
          showConfirmButton: false,
          timer: 2000,
        });
        getStatusAPI();
    } catch (error) {
      swal.fire({
        icon: "error",
        text: error,
        showConfirmButton: false,
        timer: 2000,
      });
      return error;
    }
  }

  return (
    <Stack>
      <Paper
        elevation={currentstep ? 6 : 0}
        style={{
          marginBottom: "16px",
          width: "100%",
          height: currentstep ? "629px" : "570px",
          borderRadius: "12px",
          border: currentstep ? " 1px solid #3567D6" : "1px solid #C4CAD5",
          background: currentstep ? "#3567D6" : "none",
        }}
      >
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            margin={"16px"}
            fontSize={"16px"}
            fontWeight={600}
            color={currentstep ? "#fff" : "#000"}
          >
            {stepData?.step_name}
          </Typography>
         {loginData?.role === "admin" && <Stack direction={"row"}>
            <IconButton
              sx={{ height: "fit-content" }}
              onClick={handleUpdatePopupOpen}
            >
              <EditIcon style={{ color: currentstep ? "#fff" : "#000" }} />
            </IconButton>
            <IconButton
              sx={{ height: "fit-content" }}
              onClick={() => {
                onClickDeleteExperience(stepData._id);
              }}
            >
              <DeleteIcon style={{ color: currentstep ? "#fff" : "#000" }} />
            </IconButton>
          </Stack>}
        </Stack>
        <Box
          style={{ width: "100%", backgroundColor: "#DADDE5", height: "1px" }}
        />
        <Stack
          justifyContent={"space-between"}
          height={currentstep ? "550px" : "490px"}
        >
          <Stack direction={"row"} gap={2} marginTop={"14px"}>
            <Stack style={{ marginLeft: "16px" }}>
              {stepData?.substeps?.map((substep, i, array) => (
                <>
                  <Stack direction={"row"} gap={2} key={i}>
                    {" "}
                    {substep?.is_substep_completed ? (
                      <CheckCircleIcon
                        style={{ fill: currentstep ? "#fff" : "212121" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fill: currentstep ? "#fff" : "212121" }}
                      />
                    )}
                    <Tooltip title={substep?.substep_name}>
                    <Typography
                      fontSize={"16px"}
                      lineHeight={"24px"}
                      fontWeight={400}
                      color={currentstep ? "#fff" : "212121"}
                    >
                      {trimStringAtThirdSpace(substep?.substep_name)}
                    </Typography>
                    </Tooltip>
                  </Stack>
                  {i !== array.length - 1 && (
                    <Box
                      style={{
                        marginLeft: "10px",
                        marginBottom: "4px",
                        marginTop: "4px",
                        height: "8px",
                        width: "2px",
                        backgroundColor: currentstep ? "#fff" : "#212121",
                      }}
                      color={currentstep ? "#fff" : "212121"}
                    />
                  )}
                </>
              ))}
            </Stack>
          </Stack>
          <Stack>
            <Box
              style={{
                width: "100%",
                backgroundColor: "#DADDE5",
                height: "1px",
              }}
            />
            <Stack style={{ marginLeft: "16px" }}>
              <Typography
                fontSize={"20px"}
                fontWeight={600}
                color={currentstep ? "#fff" : "#000"}
              >
                Dependencies:{" "}
              </Typography>
              <Stack direction={"row"} gap={"8px"} marginLeft={"20px"}>
        {avatars.map((avatar, index) => (
          <Tooltip title={<Paper sx={{margin:"-20px",background: currentstep ? "#000" : "#fff", }}  ><Typography style={{padding:"10px", color : currentstep ? "#fff" : "#000",  fontSize : "16px" }}> {stepData?.dependencyArray[index]} </Typography></Paper>} placement="top">
          <Avatar sx={{ width: 24, height: 24, marginLeft:"-20px"}} key={index} src={avatar.src} alt={avatar.alt} />
          </Tooltip>
        ))}   
              <Typography
                fontSize={"16px"}
                fontWeight={400}
                color={currentstep ? "#fff" : "#000"}
              >
                {stepData.dependencyArray.length} People{" "}
              </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      {currentstep && (
        <Typography textAlign={"center"} color={"#FF0000"}>
          {calculateTimeRemaining(stepData.deadline_date)}{" "}
        </Typography>
      )}
      {stepData.status === "completed" && (
        <Typography textAlign={"center"} color={"#FF0000"}>
          {calculateTimeRemaining(stepData.deadline_date, stepData.completed_at)}{" "}
        </Typography>
      )}

      <UpdateStepStatusPopup
        stepData={stepData}
        open={isUpdatePopupOpen}
        onClose={handleUpdatePopupClose}
        idToken={idToken}
        getStatusAPI={getStatusAPI}
      />
    </Stack>
  );
};

export default StatusCard;
