import React, { useEffect, useState } from "react";
import {
  Navbar,
  Nav,
  NavDropdown,
  Container,
  NavbarBrand,
  Dropdown,
  Modal,
  Spinner,
} from "react-bootstrap";
import DownloadSvg from "../svgs/DownloadSvg";
import LogoutComponent from "./LogoutComponent";
import ThyrocareIcon from "../svgs/ThyrocareIcon";
import UserSvg from "../svgs/UserSvg";
import "../css/navbarCss.css";
import NiroggyanSvg from "../svgs/NiroggyanSvg";
import axios from "axios";
import { orgCenterData } from "../specs/orgCenterData";
import { Button, FormControl } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const NavbarComponent = () => {
  const [loginData, setLoginData] = useState("");
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [loadingCenterData, setLoadingCenterData] = useState(false);
  const [orgAndCentersData, setOrgAndCentersData] = useState([]);
  const [selectionBasedCenters, setSelectionBasedCenters] = useState([]);
  const [orgChoosen, setOrgChoosen] = useState("");
  const [centerChoosen, setCenterChoosen] = useState("");
  const [typeOfNewClient, setTypeOfNewClient] = useState("");

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // modal state and functions
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  // maintenance page
  const [maintenance, setMaintenance] = useState(false);

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  let logoToUse =
    localStorage.getItem("logoUrl") === "undefined"
      ? "https://res.cloudinary.com/nithish0504/image/upload/v1652856436/niroggyan-logo_pc2fef.svg"
      : JSON.parse(localStorage.getItem("logoUrl"));

  // let loginStatus = JSON.parse(localStorage.getItem("loginDetails"))

  console.log("logo to use: ", logoToUse);
  // console.log("loginStatus\n", loginStatus)

  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    const maintenance = JSON.parse(localStorage.getItem("maintenance"));
    setMaintenance(maintenance);

    if (loginInfo) {
      setLoginData(loginInfo);
    }
  }, [reRenderComponent]);

  // getting orgs and centres
  useEffect(() => {
    const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let allData = [];
    if (loginInfo) {
      // for (const property in orgCenterData) {
      //   // console.log(`=> ${property}: ${orgCenterData[property]}`);
      //   let theKeyAndValue = `${property}: ${orgCenterData[property]}`;
      //   allData.push({ theOrg: property, theCenters: orgCenterData[property] });
      // }
      let data = [];
      let Data = {};
      for (let index in loginInfo.clientData) {
        if (loginInfo.clientData[index].org in Data) {
          Data[loginInfo.clientData[index].org].theCenters.push(
            loginInfo.clientData[index].center
          );
        } else {
          Data[loginInfo.clientData[index].org] = {
            theOrg: loginInfo.clientData[index].org,
            theCenters: [loginInfo.clientData[index].center],
          };
        }
      }
      for (let each in Data) {
        data.push(Data[each]);
      }
      console.log("loginInfo.clientData", loginInfo.clientData);
      // console.log(allData);
      // console.log(data);
      setOrgAndCentersData(data);
    }
  }, [reRenderComponent]);

  const getCenterData = async (theId) => {
    console.log("getting center data", theId);
    handleShow();

    let endpointToUse;

    if (theId === "trial") {
      endpointToUse = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/counts/?org=${
        loginData ? loginData.org : ""
      }&center=trial`;
    } else {
      endpointToUse = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${
        loginData ? loginData.org : ""
      }&center=${theId}`;
    }

    var config = {
      method: "GET",
      //url: '',
      url: endpointToUse,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (theId === "trial") {
        const axiosData = await axios(config);
        console.log(`center and org data\n`, axiosData);
        localStorage.setItem("trialClientsData", JSON.stringify(axiosData));
        localStorage.setItem("trialClientPreference", JSON.stringify("yes"));
        window.dispatchEvent(new Event("storage"));
        setReRenderComponent(!reRenderComponent);
        setTimeout(() => {
          handleClose();
        }, 1500);
      } else {
        // EXPIRING LOGIN SESSION
        // var datetime = new Date().toLocaleString().replace(',', '')

        const axiosData = await axios(config);
        //console.log(`center data of ${theId}\n`, axiosData)
        localStorage.setItem(
          "homepageData",
          JSON.stringify({ data: axiosData.data })
        );
        localStorage.setItem("clientPreference", JSON.stringify(theId));
        setReRenderComponent(!reRenderComponent);
        window.dispatchEvent(new Event("storage"));
        setTimeout(() => {
          handleClose();
        }, 1500);
        //window.location.reload()
        // localStorage.setItem("centerDetails", JSON.stringify(axiosData.data))
      }
    } catch (error) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      console.log("Center Endpoint Error:\n", error);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      style={{ textDecoration: "none", color: "inherit" }}
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      &#x25bc;
    </a>
  ));

  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="search org"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const CustomMenu2 = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="search center"
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );

  const chooseCenterHandler = (theData) => {
    console.log("chooseCenterHandler have:", theData.theOrg);
    setOrgChoosen(theData.theOrg);
    setSelectionBasedCenters(theData.theCenters);
  };

  const customOrgAndCenterApiHandler = async () => {
    handleClose2();
    handleShow();

    let endpointToConsider;

    if (centerChoosen === "trial") {
      endpointToConsider = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/counts/?org=${orgChoosen}&center=trial`;
    } else {
      endpointToConsider = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${orgChoosen}&center=${centerChoosen}`;
    }

    console.log("the center and org endpoint\n", endpointToConsider);

    var config = {
      method: "GET",
      //url: '',
      url: endpointToConsider,
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      if (centerChoosen === "trial") {
        const axiosData = await axios(config);
        console.log(`center and org data\n`, axiosData);
        localStorage.setItem("trialClientsData", JSON.stringify(axiosData));
        localStorage.setItem("trialClientPreference", JSON.stringify("yes"));
        window.dispatchEvent(new Event("storage"));
        setReRenderComponent(!reRenderComponent);
        setTimeout(() => {
          handleClose();
        }, 1500);
      } else {
        // const axiosData = await axios(config);
        // console.log(`center and org data\n`, axiosData);
        // localStorage.setItem(
        //   'homepageData',
        //   JSON.stringify({ data: axiosData.data })
        // );
        localStorage.setItem("clientPreference", JSON.stringify(centerChoosen));
        localStorage.setItem("orgPreference", JSON.stringify(orgChoosen));
        // set clientId of the center selected
        const loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
        const clientId = loginInfo.clientData.filter(
          (each) => each.org === orgChoosen
        )[0].clientId;

        localStorage.setItem("clientIdPreference", JSON.stringify(clientId));
        window.dispatchEvent(new Event("storage"));
        setReRenderComponent(!reRenderComponent);
        window.location.replace("/new-billing-dashboard");
        setTimeout(() => {
          handleClose();

          window.location.reload();
        }, 1500);
        //window.location.reload()
        // localStorage.setItem("centerDetails", JSON.stringify(axiosData.data))
      }
    } catch (error) {
      setTimeout(() => {
        handleClose();
      }, 1500);
      console.log("Center Endpoint Error:\n", error);
    }
  };

  const handleNewClients = (clientRecieved) => {
    console.log("called..");
    setTypeOfNewClient(clientRecieved);
    handleClose2();
    window.open(`/new-client/${clientRecieved}`, "_self");
  };

  console.log("choosen data: ", orgChoosen, centerChoosen);

  return (
    <>
      {/* Modal Start (for loading center data) */}
      <div>
        <>
          <Modal show={show} onHide={handleClose} backdrop="static" centered>
            <Modal.Body style={{ textAlign: "center" }}>
              <Spinner animation="border" size="sm" /> Loading Data ...
            </Modal.Body>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      {/* Modal Start (for dynamic org and center) */}
      <div>
        <>
          <Modal show={show2} onHide={handleClose2}>
            <Modal.Header closeButton>
              <Modal.Title
                style={{ color: "#190e6c", display: "flex", gap: "12px" }}
              >
                <div>{"ORG & CENTER"}</div>

                <div>
                  <Dropdown style={{ position: "relative", top: "-3px" }}>
                    <Dropdown.Toggle
                      variant="info btn-sm"
                      id="dropdown-basic"
                      style={{ fontWeight: "bold" }}
                    >
                      New Client
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => handleNewClients("lab")}>
                        Add LAB
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleNewClients("lis")}>
                        Add LIS
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => handleNewClients("admin")}>
                        Add ADMIN
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                {/* <button
                                    style={{ marginLeft: "10px", fontWeight: "bold", position: "relative", top: "-3px" }}
                                    className='btn btn-sm btn-info'
                                    onClick={() => window.open("/new-client/", "_self")}
                                >
                                    {"New"} <i class="fa fa-plus-square-o" aria-hidden="true"></i>
                                </button> */}
              </Modal.Title>
            </Modal.Header>

            <Modal.Body
              style={{ display: "flex", justifyContent: "space-around" }}
            >
              {/* Choose Org Dropdown Start */}
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  {"Choose Org "}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  as={CustomMenu}
                  style={{ overflow: "scroll", height: "300px" }}
                >
                  {orgAndCentersData.map((each, idx) => (
                    <Dropdown.Item
                      eventKey={idx}
                      onClick={() => chooseCenterHandler(each)}
                    >
                      {each.theOrg}
                    </Dropdown.Item>
                  ))}
                  {/* <Dropdown.Item eventKey="3" active>
                                        Orange
                                    </Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>

              {/* Choose Org Dropdown End*/}

              {/* Choose Center Dropdown Start */}
              {orgChoosen === "" ? (
                <span style={{ color: "grey" }}>{"Choose Center"}</span>
              ) : (
                <Dropdown>
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {"Choose Center "}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    as={CustomMenu2}
                    style={{ overflow: "scroll", height: "300px" }}
                  >
                    {selectionBasedCenters.map((each, idx) => (
                      <Dropdown.Item
                        eventKey={idx}
                        onClick={() => setCenterChoosen(each)}
                      >
                        {each}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              {/* Choose Center Dropdown End */}
            </Modal.Body>

            <Modal.Body style={{ fontSize: "14px" }}>
              <span style={{ fontWeight: "550" }}>{"Org: "}</span>
              <span style={{ color: "#7787bc" }}>{orgChoosen}</span>
              {" | "}
              <span style={{ fontWeight: "550" }}>{"Center: "}</span>
              <span style={{ color: "#7787bc" }}>{centerChoosen}</span>
            </Modal.Body>
            <Modal.Footer
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <a href="/subscription">
                <Button
                  disabled
                  onClick={() =>
                    localStorage.setItem(
                      "choosenClient",
                      JSON.stringify({ org: orgChoosen, center: centerChoosen })
                    )
                  }
                  variant="outline-success btn-sm d-none"
                >
                  {"Edit Subscription"}
                </Button>
              </a>

              <div style={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="info btn-sm"
                  onClick={customOrgAndCenterApiHandler}
                >
                  Fetch Info
                </Button>
                <Button variant="primary btn-sm" onClick={handleClose2}>
                  Cancel
                </Button>
              </div>
            </Modal.Footer>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      <Navbar
        className="navbarMainCss shadow-sm mb-5 bg-white rounded"
        expand="lg"
      >
        <Container fluid>
          <Navbar.Brand href="/new-billing-dashboard">
            {/* <img className="navLogoCss" src={imageToUse === undefined ? defaultImage : imageToUse} alt="logo" /> */}
            <img className="navLogoCss" src={logoToUse} alt="logo" />
          </Navbar.Brand>
          {loginData ? (
            <>
              <Navbar.Toggle aria-controls="navbarScroll" />
              <Navbar.Collapse id="navbarScroll">
                <Nav className="me-auto my-2 my-lg-0" navbarScroll />
                <div
                  style={{
                    alignItems: "center",
                    justifyContent: "space-between",
                    paddingTop: "10px",
                  }}
                  className="d-flex"
                >
                  {/* <UserSvg /> */}
                  <div
                    className="mx-3"
                    style={{
                      display:
                        loginData && loginData.role === "LIS" ? "" : "none",
                    }}
                  >
                    {/* <DownloadSvg /> */}
                    <Dropdown>
                      <Dropdown.Toggle
                        variant=""
                        className="dropdownCss"
                        id="dropdown-basic"
                        style={{
                          backgroundColor: "#ecedef",
                          borderRadius: "20px",
                        }}
                      >
                        {"Choose Clients"}
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{ textAlign: "center" }}>
                        {loginData && loginData.role === "LIS"
                          ? loginData.liveClients.map((each, idx) => (
                              <Dropdown.Item
                                key={idx}
                                onClick={() => getCenterData(each)}
                              >
                                <span>{each}</span>
                              </Dropdown.Item>
                            ))
                          : ""}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>

                  <div
                    className="align-item-center"
                    style={{
                      marginTop: "0px",
                      marginRight: "16px",
                      display:
                        loginData && loginData.role === "admin"
                          ? "flex"
                          : "none",
                      alignItems: "center",
                    }}
                  >
                    {/* <DownloadSvg /> */}
                    <a href="/client-data">
                      <Button variant="outline-primary btn-sm mx-2">
                        {"Edit Subscription"}
                      </Button>
                    </a>
                    <div
                      onClick={handleShow2}
                      className="dropdownCss"
                      id="dropdown-basic"
                      style={{
                        position: "relative",
                        // top: '8px',
                        cursor: "pointer",
                        backgroundColor: "#ecedef",
                        // borderRadius: "20px",
                        // padding: "10px",
                      }}
                    >
                      {"Choose Org & Center"}
                    </div>
                  </div>
                  <div style={{ marginTop: "0px" }} title="logout">
                    <LogoutComponent />
                  </div>
                </div>
              </Navbar.Collapse>
            </>
          ) : (
            ""
          )}
          {/* {maintenance ? (
            <div title='logout'>
              <LogoutComponent />
            </div>
          ) : (
            ''
          )} */}
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarComponent;
