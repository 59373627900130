import React from 'react'

const CalenderSvg = () => {
    return (
        <>
            <svg style={{ position: "relative", top: "-1.5px", right: "5px" }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8907 1.40621H15.047V0H13.6408V1.40621H4.35956V0H2.95296V1.40621H2.10931C1.55008 1.40684 1.01393 1.62927 0.618497 2.0247C0.223061 2.42014 0.000628703 2.95629 0 3.51552V15.8907C0.000628703 16.4499 0.223061 16.9861 0.618497 17.3815C1.01393 17.7769 1.55008 17.9994 2.10931 18H15.8907C16.4499 17.9994 16.9861 17.7769 17.3815 17.3815C17.7769 16.9861 17.9994 16.4499 18 15.8907V3.51552C17.9994 2.95629 17.7769 2.42014 17.3815 2.0247C16.9861 1.62927 16.4499 1.40684 15.8907 1.40621ZM16.5938 15.8907C16.5936 16.0771 16.5194 16.2558 16.3876 16.3876C16.2558 16.5194 16.0771 16.5936 15.8907 16.5938H2.10931C1.9229 16.5936 1.74418 16.5194 1.61237 16.3876C1.48056 16.2558 1.40642 16.0771 1.40621 15.8907V6.60941H16.5938V15.8907ZM16.5938 5.20162H1.40621V3.51552C1.4061 3.42308 1.42421 3.33153 1.45948 3.24609C1.49476 3.16065 1.54652 3.08299 1.61181 3.01756C1.6771 2.95212 1.75464 2.90018 1.84 2.86471C1.92536 2.82924 2.01687 2.81093 2.10931 2.81083H2.95296V4.21704H4.35916V2.81083H13.6408V4.21704H15.047V2.81083H15.8907C15.9831 2.81093 16.0746 2.82924 16.16 2.86471C16.2454 2.90018 16.3229 2.95212 16.3882 3.01756C16.4535 3.08299 16.5052 3.16065 16.5405 3.24609C16.5758 3.33153 16.5939 3.42308 16.5938 3.51552V5.20162Z" fill="#B0B0B0" />
                <path d="M4.24958 8.2085H2.66602V9.39617H4.24958V8.2085Z" fill="#B0B0B0" />
                <path d="M7.02009 8.20837H5.43652V9.39605H7.02009V8.20837Z" fill="#B0B0B0" />
                <path d="M9.79157 8.20837H8.20801V9.39605H9.79157V8.20837Z" fill="#B0B0B0" />
                <path d="M12.5631 8.2085H10.9795V9.39617H12.5631V8.2085Z" fill="#B0B0B0" />
                <path d="M15.3345 8.2085H13.751V9.39617H15.3345V8.2085Z" fill="#B0B0B0" />
                <path d="M4.24958 10.9796H2.66602V12.1673H4.24958V10.9796Z" fill="#B0B0B0" />
                <path d="M7.02009 10.9797H5.43652V12.1674H7.02009V10.9797Z" fill="#B0B0B0" />
                <path d="M9.79157 10.9797H8.20801V12.1674H9.79157V10.9797Z" fill="#B0B0B0" />
                <path d="M12.5631 10.9797H10.9795V12.1674H12.5631V10.9797Z" fill="#B0B0B0" />
                <path d="M4.24958 13.7505H2.66602V14.9382H4.24958V13.7505Z" fill="#B0B0B0" />
                <path d="M7.02009 13.7505H5.43652V14.9382H7.02009V13.7505Z" fill="#B0B0B0" />
                <path d="M9.79157 13.7505H8.20801V14.9382H9.79157V13.7505Z" fill="#B0B0B0" />
                <path d="M12.5631 13.7505H10.9795V14.9382H12.5631V13.7505Z" fill="#B0B0B0" />
                <path d="M15.3345 10.9797H13.751V12.1674H15.3345V10.9797Z" fill="#B0B0B0" />
            </svg>
        </>
    )
}

export default CalenderSvg