/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';
import { dashboardInfo } from '../../specs/dashboardInfo';
import { Card, Badge, Button, Spinner } from 'react-bootstrap';
import '../../css/welcomeScreenAndPlanSectionCss.css';
import { useNavigate } from 'react-router-dom';
// import { dummyData } from '../dummyData';
import axios from 'axios';
// import { FetchPercentage } from '../FetchPercentage';
import PlanDetailsSection from '../VizAppDashboardSections/PlanDetailsSection';

const WelcomeScreenAndPlanSection = ({ productType }) => {
  const navigate = useNavigate();
  // const currentMonthReportCounts = [];

  const [loginData, setLoginData] = useState('');
  const [clienInfo, setClienInfo] = useState('');
  const [reRenderComponent, setReRenderComponent] = useState(false);

  const [loader, setLoader] = useState(false);

  const [numberOfReportsConsumed, setNumberOfReportsConsumed] = useState(0);
  const [reportsSubscribed, setReportsSubscribed] = useState(1);

  // Number of Reports Subscribed
  // const subscriptionOfReports = 750

  // const currentMonthReportsTotal = dummyData[0].current_month.day_wise

  // // calcuating current month report count
  // for (let x = 0; x < currentMonthReportsTotal.length; x++) {
  //     let currentMonthObj = currentMonthReportsTotal[x]
  //     for (let thisObj in currentMonthObj) {
  //         currentMonthReportCounts.push(currentMonthObj.count)
  //     }
  // }

  window.addEventListener('storage', e => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  // console.log('numberOfReportsConsumed', numberOfReportsConsumed);
  // checking credentials
  useEffect(() => {
    const credentialsInfo = JSON.parse(localStorage.getItem('myLoginDetails'));
    const checkClientPreference = JSON.parse(
      localStorage.getItem('clientPreference')
    );

    let data = JSON.parse(localStorage.getItem('loginDetails'));
    setReportsSubscribed(data.reportsSubscribed);

    if (checkClientPreference) {
      setClienInfo(checkClientPreference);
    }

    if (credentialsInfo && data) {
      if (credentialsInfo === data.userId) {
        //console.log("login allowed.")
        navigate('/new-billing-dashboard');
        setLoginData(data);
      }
    } else {
      console.log('invalid login details.');
      navigate('/login');
    }
  }, [reRenderComponent]);

  useEffect(() => {
    // checking date
    // current date
    let todaysDate = new Date().toJSON().slice(0, 10).replace(/-/g, '/');

    let todaysDatePart1 = todaysDate.slice(0, 4);
    let todaysDatePart2 = todaysDate.slice(5, 7);
    let todaysDatePart3 = todaysDate.slice(8, 10);

    const getSavedDate = JSON.parse(localStorage.getItem('loggedOn'));

    let savedDatePart1 = getSavedDate ? getSavedDate.slice(0, 4) : '';
    let savedDatePart2 = getSavedDate ? getSavedDate.slice(5, 7) : '';
    let savedDatePart3 = getSavedDate ? getSavedDate.slice(8, 10) : '';

    let status1;
    let status2;
    let status3;

    console.log('part1', todaysDatePart1, savedDatePart1);
    console.log('part2', todaysDatePart2, savedDatePart2);
    console.log('part3', todaysDatePart3, savedDatePart3);

    let statusOfLoggedOn;

    if (todaysDatePart1 === savedDatePart1) {
      status1 = true;
    } else {
      status1 = false;
    }
    if (todaysDatePart2 === savedDatePart2) {
      console.log('');
      status2 = true;
    } else {
      status2 = false;
    }
    if (todaysDatePart3 === savedDatePart3) {
      status3 = true;
    } else {
      status3 = false;
    }

    console.log('statusOfLoggedOn', statusOfLoggedOn);

    if (status1 === true && status2 === true && status3 === true) {
      //
    } else {
      localStorage.setItem('myLoginDetails', JSON.stringify(''));
      localStorage.removeItem('loggedOn');
      localStorage.removeItem('loginDetails');
      localStorage.removeItem('homepageData');
      localStorage.setItem(
        'logoUrl',
        JSON.stringify(
          'https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/niroggyan-logo.svg'
        )
      );
      window.dispatchEvent(new Event('storage'));
      window.location.reload();
    }

    (async function () {
      await fetchReportCounts();
    })();
  }, [productType]);

  const fetchReportCounts = async () => {
    setLoader(true);
    const endpointToCall = `https://api.niroggyan.com/billing/${productType}/counts?query_on=counts&query_by=subscription`;
    const idToken = localStorage.getItem('idTokenBilling');
    let config = {
      url: endpointToCall,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: idToken,
      },
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };

    try {
      const axiosData = await axios(config);
      if (axiosData.data.isSuccess) {
        let reportCountData = axiosData.data.data[0];
        if (reportCountData) {
          setNumberOfReportsConsumed(parseInt(reportCountData.count));
          setLoader(false);
        }
      }
    } catch (error) {
      console.log('Report count Error:\n', error);
    }
    setLoader(false);
  };

  // LOGOUT TIMER
  // useEffect(() => {
  //     if (counter < 1200) {
  //       setTimeout(() => {
  //         setCounter(counter + 1)
  //         // console.log("counter: ", counter)
  //       }, 1000)
  //     }

  //     if (counter === 1200) {
  //         alert("Session Expired loggin again!")
  //         localStorage.setItem("myLoginDetails", JSON.stringify(""))
  //         localStorage.removeItem("loginDetails")
  //         localStorage.removeItem("homepageData")
  //         localStorage.setItem("logoUrl", JSON.stringify("https://niroggyan.s3.ap-south-1.amazonaws.com/new-billing-dashboard-files/logos/niroggyan-logo.svg"))
  //         window.dispatchEvent(new Event("storage"));
  //         window.location.reload()
  //     }
  //   }, [counter])

  const resetToLIS = async () => {
    //console.log("LIS reset request")

    let orgName = loginData ? loginData.org : '';
    let centerName = loginData ? loginData.center : '';
    let lisName = loginData ? loginData.role : '';

    let homePageApiForLIS = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${orgName}&center=${centerName}&query_on=${lisName}`;

    var config = {
      method: 'GET',
      // url: "",
      url: homePageApiForLIS,
      headers: {
        'Content-Type': 'application/json',
      },
    };

    try {
      // const axiosData = await axios(config);
      localStorage.removeItem('trialClientsData');
      localStorage.removeItem('trialClientPreference');
      // localStorage.setItem(
      //   'homepageData',
      //   JSON.stringify({ data: axiosData.data })
      // );
      localStorage.setItem('clientPreference', JSON.stringify('none'));
      localStorage.removeItem('orgPreference');
      setReRenderComponent(!reRenderComponent);
      window.dispatchEvent(new Event('storage'));
      window.location.reload();
    } catch (error) {
      console.log('LIS reset error: ', error);
    }
  };

  // PERCENTAGE CALCULATOR

  // const [numberOfReportsConsumed, setNumberOfReportsConsumed] = useState("0")
  // const [reportsSubscribedInfo, setReportsSubscribedInfo] = useState("0")

  // const [percentage, setPercentage] = useState("0")

  // useEffect(() => {
  //     //console.log("calling plan details section")
  //     const loginInfo = JSON.parse(localStorage.getItem("loginDetails"))
  //     let homepageData = JSON.parse(localStorage.getItem("homepageData"))
  //     let clientInfo = JSON.parse(localStorage.getItem("clientPreference"))

  //     if (loginInfo) {
  //         setReportsSubscribedInfo(loginInfo.reportsSubscribed)
  //         fetchReportCounts()
  //     }

  // }, [reRenderComponent])

  // const fetchReportCounts = async () => {
  //     let requiredData = JSON.parse(localStorage.getItem("loginDetails"))

  //     //console.log("fetching report counts....")
  //     // fetching reports consumed by client
  //     const endpointToCall = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/clientdashboard/home/?org=${requiredData.org}&center=${requiredData.center}&startDate=${requiredData.startDate}&endDate=${requiredData.endDate}&query_on=${requiredData.role}`

  //     //console.log("this one: ", endpointToCall)

  //     let config = {
  //         url: endpointToCall,
  //         method: 'GET',
  //         headers: {
  //             'Content-Type': 'application/json'
  //         },
  //         //data: dataToSend,
  //     };

  //     //console.log("report count json:\n")
  //     //console.log(dataToSend)

  //     try {
  //         const axiosData = await axios(config)
  //         //console.log("inside axiosdata Report count  ===> \n\n", axiosData.data.subscription_data)
  //         let reportCountData = axiosData.data.subscription_data
  //         //localStorage.setItem("homepageData", JSON.stringify({ "data": axiosData.data }))
  //         // window.dispatchEvent(new Event("storage"));
  //         if (reportCountData) {
  //             setNumberOfReportsConsumed(parseInt(reportCountData.subscription_usage))
  //         }

  //     } catch (error) {
  //         console.log("Report count Error:\n", error)
  //     }
  // }

  // // calculate percentage
  // //    useEffect(() => {

  // // if (numberOfReportsConsumed !== 0) {
  // //     // set percentage
  // //     const thePercentage = Math.round((numberOfReportsConsumed / reportsSubscribedInfo : 0) * 100)

  // //     if (thePercentage > 100) {
  // //         setPercentage(100)
  // //     } else {
  // //         setPercentage(thePercentage)
  // //     }
  // // }

  // // }, [numberOfReportsConsumed, percentage])

  // // calculate percentage
  // useEffect(() => {

  //     const thePercentage = Math.round((numberOfReportsConsumed / reportsSubscribedInfo) * 100)
  //     // set percentage

  //     if (thePercentage > 100) {
  //         setPercentage(100)
  //     } else {
  //         setPercentage(thePercentage)
  //     }

  // }, [numberOfReportsConsumed, percentage])

  // console.log("the percentage: ", percentage)

  let colorCondition =
    loginData && loginData.subscriptionStatus === 'on hold'
      ? '#ff4d4d'
      : '#27BC48';

  return (
    <div className='mb-4'>
      {/* Welcome Heading */}

      <div className='welcomeContentMargins'>
        <span className='welcomeNoteCss'>
          {/* {dashboardInfo.welcomeScreen.welcomeNote} */}
          {'Welcome'}
        </span>
        <span className='welcomeClientCss'>
          {loginData ? loginData.displayName : ''}
        </span>
      </div>

      <Button
        className={'removeFocusCss badgeMarginCss'}
        style={{
          display: clienInfo && clienInfo === 'none' ? 'none' : '',
          cursor: 'text',
          backgroundColor: '#3567d6',
          position: 'relative',
        }}
      >
        {`Client ID: ${clienInfo}`}
        <Badge
          bg='info'
          title={'reset to default client'}
          onClick={resetToLIS}
          style={{
            width: '23px',
            height: '23px',
            cursor: 'pointer',
            position: 'absolute',
            top: '-10px',
            borderRadius: '100%',
          }}
        >
          <span style={{ position: 'relative', top: '1px' }}>{'X'}</span>
        </Badge>
      </Button>

      {/* Welcome sreen and offering */}
      <div
        className='welcomeScreenResolutionCss'
        style={{ display: clienInfo && clienInfo === 'none' ? '' : 'none' }}
      >
        {/* Left Content */}

        <div className='welcomeScreenLeftContentCss'>
          <Card className='' style={{ border: 'none', position: 'relative' }}>
            <Card.Img
              src='https://niroggyan.s3.ap-south-1.amazonaws.com/React-Billing-Dashboard-files/WelcomeScreen+Files/welcomeScreenSvg.svg'
              alt='Card image'
            />
            <Card.ImgOverlay className='offeringCardContentsCss'>
              <Card.Title className='offeringHeadingCss'>
                {'Offering Subscribed'}
              </Card.Title>
              <Card.Text className='offeringSubscribeForCss'>
                {loginData ? loginData.subscription : ''}
              </Card.Text>
              <Card.Text>
                <button className='downloadBrochureButtonCss'>
                  <a
                    style={{ textDecoration: 'none', color: 'inherit' }}
                    href={'https://brochure.niroggyan.com/'}
                    target={'_blank'}
                    rel='noreferrer'
                  >
                    <span className='downloadBrochureButtonContentCss'></span>
                    {/* {window.innerWidth < 600 ? dashboardInfo.welcomeScreen.brochureButtonInfoShort : dashboardInfo.welcomeScreen.brochureButtonInfo} */}

                    <svg
                      style={{ position: 'relative', left: '5px', top: '-1px' }}
                      width='14'
                      height='10'
                      viewBox='0 0 14 10'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M12.4387 6.13439C12.7549 5.80831 12.9146 5.35828 12.9168 4.94832C12.919 4.53857 12.7641 4.08686 12.4515 3.75719L9.71512 0.873708C9.48049 0.62646 9.12686 0.624564 8.88962 0.869282C8.65235 1.114 8.65037 1.48267 8.885 1.7299L11.3477 4.32502L1.67683 4.27338C1.36244 4.2717 1.08572 4.5571 1.08396 4.88488C1.0822 5.21264 1.35583 5.501 1.67024 5.50269L11.3012 5.55433L8.81088 8.12289C8.57362 8.36761 8.57164 8.73628 8.80627 8.9835C9.0409 9.23073 9.39453 9.23265 9.63178 8.98793L12.4387 6.13439Z'
                        fill='white'
                        stroke='white'
                      />
                    </svg>
                  </a>
                </button>
              </Card.Text>

              {/* <div className="offeringCardItemsCss">

                                <div className="offeringHeadingCss">
                                    {dashboardInfo.welcomeScreen.offeringHeading}
                                </div>
                                <div className="offeringSubscribeForCss">
                                    {dashboardInfo.welcomeScreen.subscribedFor}
                                </div>

                                <button className="downloadBrochureButtonCss">
                                    {dashboardInfo.welcomeScreen.brochureButtonInfo}
                        
                                    <svg style={{ marginLeft: "10px" }} width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M20.9999 10.5135V14.8378C20.9999 16.0172 19.9753 17 18.7458 17H3.25411C2.02462 17 1 16.0172 1 14.8378V10.5135C1 10.1991 1.28688 9.92393 1.61466 9.92393C1.94245 9.92393 2.22932 10.1991 2.22932 10.5135V14.8378C2.22932 15.3882 2.68019 15.8207 3.25395 15.8207H18.7457C19.3194 15.8207 19.7703 15.3882 19.7703 14.8378V10.5135C19.7703 10.1991 20.0572 9.92393 20.385 9.92393C20.7132 9.92393 21 10.1599 21 10.5135H20.9999ZM9.81163 12.3613C10.1394 12.6757 10.5903 12.833 11.0002 12.833C11.41 12.833 11.8609 12.6757 12.1889 12.3613L15.0576 9.60954C15.3036 9.37358 15.3036 9.01995 15.0576 8.78402C14.8117 8.54807 14.443 8.54807 14.197 8.78402L11.6151 11.2606L11.6149 1.58959C11.6149 1.27519 11.328 1 11.0003 1C10.6725 1 10.3856 1.27518 10.3856 1.58959V11.2207L7.80371 8.74417C7.55773 8.50822 7.18905 8.50822 6.94309 8.74417C6.69712 8.98013 6.6971 9.33376 6.94309 9.56969L9.81163 12.3613Z" fill="white" stroke="white" />
                                    </svg>

                                </button>


                            </div> */}
            </Card.ImgOverlay>
          </Card>
        </div>

        {/* Right Content */}

        <div className='welcomeScreenRightContentCss'>
          <span>
            <Card className='planCardCss'>
              <Card.Body>
                <Card.Title>
                  {dashboardInfo.welcomeScreen.planInfo.planCardHeading}

                  {/* Plan Active Status Dot */}

                  <svg
                    style={{ float: 'right', position: 'relative', top: '5px' }}
                    width='18'
                    height='18'
                    viewBox='0 0 18 18'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <circle
                      cx='9'
                      cy='9'
                      r='8'
                      stroke={colorCondition}
                      stroke-width='1.38462'
                    />
                    <circle
                      cx='9.15385'
                      cy='9.15385'
                      r='6.15385'
                      fill={colorCondition}
                    />
                  </svg>
                </Card.Title>

                <Card.Subtitle className='text-muted'>
                  <div className='planCardSubtitleCss'>
                    <span className='planPlaceholderCss'>
                      {/* here */}
                      {dashboardInfo.welcomeScreen.planInfo.planPlaceHolder}
                    </span>
                    <span
                      className='planActiveStatusCss'
                      style={{
                        color: '#fff',
                        backgroundColor: colorCondition,
                      }}
                    >
                      {loginData && loginData.subscriptionStatus
                        ? loginData.subscriptionStatus.toUpperCase()
                        : 'None'}
                      {/* {} */}
                    </span>
                  </div>
                </Card.Subtitle>

                <Card.Subtitle
                  className='text-muted subscriptionWarningCss'
                  style={{
                    display:
                      Math.round(
                        (numberOfReportsConsumed / reportsSubscribed) * 100
                      ) > 80 &&
                      loginData &&
                      loginData.role === 'lab'
                        ? ''
                        : 'none',
                  }}
                >
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                    }}
                  >
                    <div
                      style={{
                        width: '50px',
                      }}
                    >
                      <i
                        class='fa fa-exclamation-triangle text-warning fa-2x'
                        aria-hidden='true'
                      ></i>
                    </div>
                    <div
                      className='col'
                      style={{
                        width: '100%',
                      }}
                    >
                      <span className='subscriptionWarningTextCss'>
                        <span>{'you have used'} </span>{' '}
                        <span style={{ fontWeight: 'bold', color: 'black' }}>
                          {/* {FetchPercentage()} */}
                          {Math.round(
                            (numberOfReportsConsumed / reportsSubscribed) * 100
                          )}
                          {'%'}
                        </span>{' '}
                        <span>
                          {
                            ' of your usage plan, please contact the business team for an extention'
                          }
                        </span>
                      </span>
                    </div>
                  </div>
                </Card.Subtitle>

                <Card.Text
                  className='text-muted planMoreInfoCss'
                  style={{
                    display:
                      (loginData &&
                        loginData.role === 'LIS' &&
                        loginData.showBilling === true) ||
                      (loginData && loginData.role === 'lab')
                        ? ''
                        : 'none',
                  }}
                >
                  <div className='startDateCss'>
                    <span style={{ color: '#7787bc' }}>{'Start Date: '}</span>
                    <span style={{ fontWeight: '550' }}>
                      {loginData
                        ? loginData.startDate
                            .split(' ')[0]
                            .split('-')
                            .reverse()
                            .join('-')
                        : ''}
                    </span>
                  </div>
                  <div className='endDateCss'>
                    <span style={{ color: '#7787bc' }}>{'End Date: '}</span>
                    <span style={{ fontWeight: '550' }}>
                      {loginData
                        ? loginData.endDate
                            .split(' ')[0]
                            .split('-')
                            .reverse()
                            .join('-')
                        : ''}
                    </span>
                  </div>
                </Card.Text>

                {/* For LIS (below condition) */}

                <Card.Text
                  className='text-muted planMoreInfoCss'
                  style={{
                    display:
                      loginData &&
                      loginData.role === 'LIS' &&
                      loginData.showBilling !== true
                        ? ''
                        : 'none',
                  }}
                >
                  <div>
                    <span style={{ color: '#7787bc' }}>{'LIS'}</span>
                  </div>
                </Card.Text>

                {/* For ADMIN (below condition) */}

                <Card.Text
                  className='text-muted planMoreInfoCss'
                  style={{
                    display:
                      loginData && loginData.role === 'admin' ? '' : 'none',
                  }}
                >
                  <div>
                    <span style={{ color: '#7787bc' }}>{'Admin'}</span>
                  </div>
                </Card.Text>
              </Card.Body>
            </Card>
          </span>
        </div>
      </div>

      {loader ? (
        <Spinner
          className='spinner-border'
          style={{ fontWeight: 'normal' }}
          id='loadingSpinnerCss'
          animation='border'
          role='status'
        />
      ) : (
        <PlanDetailsSection reportsConsumed={numberOfReportsConsumed} />
      )}
    </div>
  );
};

export default WelcomeScreenAndPlanSection;
