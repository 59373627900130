import React, { useEffect, useState } from "react";
import ClientDistributonSection from "./VizAppDashboardSections/ClientDistributionSection";
import MetricsSection from "./VizAppDashboardSections/MetricsSection";
import PlanDetailsSection from "./VizAppDashboardSections/PlanDetailsSection";
import TopFivePackageSection from "./VizAppDashboardSections/TopFivePackageSection";
import WelcomeScreenAndPlanSection from "./VizAppDashboardSections/WelcomeScreenAndPlanSection";
import YearWiseMetrics from "./VizAppDashboardSections/YearWiseMetrics";
import TableComponent from "./TableComponent";

import MaintenancePage from "./maintenance";
import LoginOAuth from "./VizAppDashboardSections/loginPageOAuth";
import OnboardingStatus from "./onboardingStatus/OnboardingStatus";
import { InputLabel, MenuItem, Select } from "@mui/material";

const VizAppDashboard = () => {
  const [clientPreferenceRecord, setClientPreferenceRecord] = useState(false);
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [roleInfo, setRoleInfo] = useState("");
  const [trialClientData, setTrialClientData] = useState("");
  const [productType, SetProductType] = useState("smartreport");

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  useEffect(() => {
    let clientPreferenceInfo = JSON.parse(
      localStorage.getItem("clientPreference")
    );
    let loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let trialClientInfo = JSON.parse(
      localStorage.getItem("trialClientPreference")
    );

    if (clientPreferenceInfo) {
      setClientPreferenceRecord(clientPreferenceInfo);
    }

    if (loginInfo) {
      console.log("role:", loginInfo.role);
      setRoleInfo(loginInfo.role);
    }

    if (trialClientInfo) {
      setTrialClientData(trialClientInfo);
    }
  }, [reRenderComponent]);

  console.log("here:", localStorage.getItem("logoUrl"));

  return (
    <>
      {trialClientData === "yes" ? (
        <>
          <TableComponent />
        </>
      ) : (
        <>
          <OnboardingStatus />
          <InputLabel
            sx={{
              color: "#170C6B",
              paddingBottom: "16px",
              fontSize: "22px",
              fontWeight: "500",
            }}
          >
            Select Product
          </InputLabel>
          <Select
            sx={{ color: "#3567d6" }}
            onChange={(e) => SetProductType(e.target.value)}
            value={productType}
          >
            <MenuItem sx={{ color: "#3567d6" }} value={"vizapp"}>
              Viz App
            </MenuItem>
            <MenuItem sx={{ color: "#3567d6" }} value={"smartreport"}>
              Smart Report
            </MenuItem>
            <MenuItem sx={{ color: "#3567d6" }} value={"videoreport"}>
              Video Report
            </MenuItem>
          </Select>

          {/* <LoginOAuth /> */}
          <div className="mb-3">
            {loginData && loginData.role === "lab" && (
              <WelcomeScreenAndPlanSection productType={productType} />
            )}
          </div>
          <MetricsSection productType={productType} />
          <TopFivePackageSection productType={productType} />

          {clientPreferenceRecord === "none" && roleInfo !== "lab" ? (
            <ClientDistributonSection productType={productType} />
          ) : (
            ""
          )}

          <YearWiseMetrics productType={productType} />
        </>
      )}
      {/* <MaintenancePage /> */}
    </>
  );
};

export default VizAppDashboard;
