import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const CreateStep = ({ open, onClose, idToken, clientId, getStatusAPI }) => {
  const initialStepData = {
    step_name: "",
    deadline_date: new Date().toISOString().split("T")[0],
    status: "notStarted",
    completed_by: null,
    dependencyArray: [],
    substeps: [],
    created_at: new Date(),
    clientId: clientId,
  };


  const [stepData, setStepData] = useState(initialStepData);

  const handleInputChange = (field, value) => {
    setStepData({ ...stepData, [field]: value });
  };

  const handleSubstepChange = (index, field, value) => {
    const updatedSubsteps = [...stepData.substeps];
    updatedSubsteps[index][field] = value;
    setStepData({
      ...stepData,
      substeps: updatedSubsteps,
    });
  };

  const addSubstep = () => {
    const newSubstep = {
      substep_name: "",
      is_substep_completed: false,
      completed_by: "",
    };
    setStepData({
      ...stepData,
      substeps: [...stepData.substeps, newSubstep],
    });
  };

  const removeSubstep = (index) => {
    const updatedSubsteps = [...stepData.substeps];
    updatedSubsteps.splice(index, 1);
    setStepData({
      ...stepData,
      substeps: updatedSubsteps,
    });
  };

  const handleSubmit = () => {
    const requestData = {
      ...stepData,
      substeps: stepData.substeps.map((substep) => ({
        substep_name: substep.substep_name,
        is_substep_completed: substep.is_substep_completed,
        completed_by: substep.completed_by || null,
        completed_at: substep.completed_at,
      })),
    };
    axios
      .post(
        "https://api.niroggyan.com/onboarding/status/createstep",
        requestData,
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      )
      .then((response) => {
        console.log("Create successful:", response.data);

        setStepData({
          ...initialStepData,
          substeps: [],
        });
        getStatusAPI();
        onClose();
        response.status === 200 &&
          Swal.fire({
            icon: "success",
            text: "Step Created Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error("Error creating step:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Step</DialogTitle>
      <DialogContent>
        <Stack gap={2} margin={"20px"}>
          <TextField
            label="Step Name"
            variant="outlined"
            fullWidth
            value={stepData.step_name}
            onChange={(e) => handleInputChange("step_name", e.target.value)}
          />
          <TextField
            label="Deadline"
            variant="outlined"
            fullWidth
            type="date"
            value={stepData.deadline_date}
            onChange={(e) => handleInputChange("deadline_date", e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={stepData.status}
              onChange={(e) => handleInputChange("status", e.target.value)}
            >
              <MenuItem value="inProgress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="notStarted">Not Started</MenuItem>
            </Select>
          </FormControl>
          {/* {stepData.status === 'completed' && (
            <FormControl>
              <InputLabel>Completed by</InputLabel>
              <Select
                sx={{ width: '200px' }}
                value={stepData.completed_by}
                onChange={(e) => handleInputChange('completed_by', e.target.value)}
              >
                {stepData?.dependencyArray?.map((email) => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )} */}
          <TextField
            label="Dependency Emails (Comma-separated)"
            variant="outlined"
            fullWidth
            value={stepData?.dependencyArray?.join(",")}
            onChange={(e) => {
              const emailArray = e.target.value
                .split(",")
                .map((email) => email.trim());
              handleInputChange("dependencyArray", emailArray);
            }}
          />
          <hr />
          <h6>Add Substeps</h6>
          {stepData.substeps.map((substep, index) => (
            <div key={index}>
              <Stack direction="column" gap={2}>
                <TextField
                  label="Substep Name"
                  variant="outlined"
                  fullWidth
                  value={substep.substep_name}
                  onChange={(e) =>
                    handleSubstepChange(index, "substep_name", e.target.value)
                  }
                />
                <Stack direction="row" justifyContent="space-between">
                  {/* {substep.is_substep_completed && (
                    <FormControl>
                      <InputLabel>Completed by</InputLabel>
                      <Select
                        sx={{ width: '200px' }}
                        value={substep.completed_by}
                        onChange={(e) => handleSubstepChange(index, 'completed_by', e.target.value)}
                      >
                         Render the options based on your requirements 
                      </Select>
                    </FormControl>
                  )}  */}
                  <Checkbox
                    sx={{ height: "fit-content" }}
                    checked={substep.is_substep_completed}
                    onChange={() =>
                      handleSubstepChange(
                        index,
                        "is_substep_completed",
                        !substep.is_substep_completed
                      )
                    }
                  />
                  <Button onClick={() => removeSubstep(index)} color="error">
                    Remove
                  </Button>
                </Stack>
              </Stack>
            </div>
          ))}
          <Button onClick={addSubstep} color="primary">
            Add Substep
          </Button>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateStep;
