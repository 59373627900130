/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { Card, Modal, Button, Spinner } from "react-bootstrap";
import CalenderSvg from "../../svgs/CalenderSvg";
import "../../css/metricsSectionCss.css";
import ReportsGeneratedSvgIcon from "../../svgs/ReportsGeneratedSvgIcon";
import axios from "axios";
import DownloadSvgSmall from "../../svgs/DownloadSvgSmall";
import { Box, Stack, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

// let dataLoaded = false;

const MetricsSection = ({ productType }) => {
  console.log("productType1", productType);
  const [currentMonthSelection, setCurrentMonthSelection] = useState(true);
  const [customMonthSelection, setCustomMonthSelection] = useState(false);
  const [downloadStatus, setDownloadStatus] = useState(false);
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [selectedFromDate, setSelectedFromDate] = useState("");
  const [selectedToDate, setSelectedToDate] = useState("");
  const [epochFromTIme, setEpochFromTime] = useState("");
  const [epochToTIme, setEpochToTime] = useState("");
  const [maxcalenderDate, SetMaxCalenderDate] = useState("");
  const [loader, setLoader] = useState(true);

  // Function for epoch TIme

  const formatFromDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    console.log(requiredTime);
    return requiredTime;
  };
  const formatToDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    console.log(requiredTime);
    return requiredTime;
  };

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  const [fetchedData, setFetchedData] = useState([]);
  const [loginData, setLoginData] = useState("");
  const [prevMonthData, setPrevMonthData] = useState("");
  const [loadingGraphValuesStatus, setLoadingGraphValuesStatus] =
    useState(false);

  // modal state and functions
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // fetch data from local storage
  useEffect(() => {
    setCurrentMonthSelection(true);
    setCustomMonthSelection(false);
    let loginDataInfo = JSON.parse(localStorage.getItem("loginDetails"));

    if (loginDataInfo) {
      setLoginData(loginDataInfo);
    }
  }, [reRenderComponent]);

  const customDatesDayWiseCounts = async () => {
    handleClose();
    setLoadingGraphValuesStatus(true);
    const response = await getDayWiseCounts(epochFromTIme, epochToTIme);
    setFetchedData(response.data.data);
    setCustomMonthSelection(true);
    setLoadingGraphValuesStatus(false);
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formatFromDate(startDate),
      endDate: formatToDate(endDate),
    };
  };

  const getDayWiseCounts = async (startDate, endDate) => {
    setLoader(true);
    const clientPreference = JSON.parse(
      localStorage.getItem("clientPreference")
    );
    const orgPreference = JSON.parse(localStorage.getItem("orgPreference"));

    const clientIdPreference = JSON.parse(
      localStorage.getItem("clientIdPreference")
    );
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "get",
      url: `https://api.niroggyan.com/billing/${productType}/counts?query_on=counts&query_by=range&start=${startDate}&end=${endDate}${
        orgPreference && clientPreference
          ? `&org=${orgPreference}&center=${clientPreference}`
          : ""
      }${clientIdPreference ? `&clientId=${clientIdPreference}` : ``}`,
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };
    const response = await axios(configBody);
    setLoader(false);
    return response;
  };

  const currentMonthDayWiseCounts = async () => {
    const startDate = getCurrentMonthStartEndDates().startDate;
    const endDate = getCurrentMonthStartEndDates().endDate;
    (async function () {
      const response = await getDayWiseCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  const previousMonthDayWiseCounts = async () => {
    const startDate = getPreviousMonthStartEndDates().startDate;
    const endDate = getPreviousMonthStartEndDates().endDate;
    (async function () {
      const response = await getDayWiseCounts(startDate, endDate);
      setFetchedData(response.data.data);
    })();
  };

  useEffect(() => {
    const startDate = getCurrentMonthStartEndDates().startDate;
    const endDate = getCurrentMonthStartEndDates().endDate;
    // dataLoaded &&
    (async function () {
      const response = await getDayWiseCounts(startDate, endDate);
      console.log("response", response);
      setFetchedData(response.data.data);
    })();

    // previous month data
    (async function () {
      const response = await getDayWiseCounts(
        getPreviousMonthStartEndDates().startDate,
        getPreviousMonthStartEndDates().endDate
      );
      console.log("responseprev", response);
      setPrevMonthData(response.data.data);
    })();
    // dataLoaded = true;
  }, [productType]);

  // using actual endpoint data
  let reportCountMonth = fetchedData
    ? fetchedData.map((each) => parseInt(each.count))
    : [];
  let datesOfMonth = fetchedData
    ? fetchedData.map((each) =>
        each.date.split("-").reverse().join("-").slice(0, -5)
      )
    : [];

  let yAxisDates;
  // Selecting the month and year to display in graph and also find average results
  let reportCount; // for x-axis and y-axis (col bar)
  let textToDisplay;
  let titleText;
  let averageToDisplay;
  let numberOfReportsInMonth;

  // else choose current month data
  reportCount = reportCountMonth;
  yAxisDates = datesOfMonth;

  let totalCurrentCounts = reportCountMonth.reduce(
    (a, b) => parseInt(a) + parseInt(b),
    0
  );
  numberOfReportsInMonth = totalCurrentCounts;
  let totalDaysCurrentMonth = datesOfMonth.length;

  if (totalCurrentCounts === 0 && totalDaysCurrentMonth === 0) {
    averageToDisplay = 0;
  } else {
    averageToDisplay = Math.round(totalCurrentCounts / totalDaysCurrentMonth);
  }
  textToDisplay = "Current Month Day Wise Data";
  titleText = "Get Current Month detailed info";

  // column graph properties
  let dataToRender = {
    options: {
      dataLabels: {
        enabled: false,
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.5,
        },
      },
      chart: {
        id: "MonthWise",
      },
      xaxis: {
        categories: yAxisDates,
      },
      colors: ["#ff6384"],
    },
    series: [
      {
        name: "Reports Generated",
        data: reportCount,
      },
    ],
  };

  // download metrics data
  const downloadMetricsHandler = async () => {
    const idToken = localStorage.getItem("idTokenBilling");
    if (customMonthSelection) {
      setDownloadStatus(true);
      let customMonthEndPoint = `https://api.niroggyan.com/billing/${productType}/data?query_on=counts&query_by=range&start=${epochFromTIme}&end=${epochToTIme}`;

      var configForCustomMonth = {
        method: "GET",
        url: customMonthEndPoint,
        headers: {
          authorization: idToken,
          "Content-Type": "application/json",
        },
      };

      try {
        const axiosDataCustom = await axios(configForCustomMonth);
        const requiredFileLink = axiosDataCustom.data.data;
        const link = document.createElement("a");
        link.href = requiredFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStatus(false);
      } catch (error) {
        setDownloadStatus(false);
      }
    } else if (!currentMonthSelection) {
      setDownloadStatus(true);
      const { startDate, endDate } = getPreviousMonthStartEndDates();
      let previousMonthEndPoint = `https://api.niroggyan.com/billing/${productType}/data?query_on=counts&query_by=range&start=${startDate}&end=${endDate}`;
      var configForPreviousMonth = {
        method: "GET",
        url: previousMonthEndPoint,
        headers: {
          authorization: idToken,
          "Content-Type": "application/json",
        },
      };

      try {
        const axiosDataPrevious = await axios(configForPreviousMonth);
        const requiredFileLink = axiosDataPrevious.data.data;
        const link = document.createElement("a");
        link.href = requiredFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        setDownloadStatus(false);
      } catch (error) {
        setDownloadStatus(false);
        console.log("axiosDataPrevious Error: ", error);
      }
    } else {
      setDownloadStatus(true);
      const { startDate, endDate } = getCurrentMonthStartEndDates();
      let currentMonthEndPoint = `https://api.niroggyan.com/billing/${productType}/data?query_on=counts&query_by=range&start=${startDate}&end=${endDate}`;
      var configForCurrentMonth = {
        method: "GET",
        url: currentMonthEndPoint,
        headers: {
          "Content-Type": "application/json",
          authorization: idToken,
        },
      };

      try {
        const axiosDataCurrent = await axios(configForCurrentMonth);
        const requiredFileLink = axiosDataCurrent.data.data;
        const link = document.createElement("a");
        link.href = requiredFileLink;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setDownloadStatus(false);
      } catch (error) {
        setDownloadStatus(false);
        console.log("axiosDataCurrent Error: ", error);
      }
    }
  };

  const setMaxDateCalender = (date) => {
    const dateFormat = new Date(date);
    const maxDate = new Date(dateFormat.setDate(dateFormat.getDate() + 31));
    const month =
      maxDate.getMonth() < 9
        ? `0${parseInt(maxDate.getMonth()) + 1}`
        : parseInt(maxDate.getMonth()) + 1;
    const day =
      maxDate.getDate() < 10 ? `0${maxDate.getDate()}` : maxDate.getDate();
    const formattedDate = `${maxDate.getFullYear()}-${month}-${day}`;

    SetMaxCalenderDate(formattedDate);
  };

  const getPrevMonthAvg = () => {
    const counts = prevMonthData.map((each) => each.count);
    const totalCount = counts.reduce((a, b) => parseInt(a) + parseInt(b));
    const prevMonthAvg = Math.round(totalCount / prevMonthData.length);
    const percentageVar =
      ((prevMonthAvg - averageToDisplay) / prevMonthAvg) * 100;

    console.log("percentageVar", totalCount, prevMonthAvg);

    return {
      prevMonthAvg: prevMonthAvg,
      percentageVar: Math.abs(percentageVar.toFixed(2)),
    };
  };

  const showVariation = () => {
    const { prevMonthAvg, percentageVar } = getPrevMonthAvg();
    const isGreater = averageToDisplay > prevMonthAvg;
    const isEqual = averageToDisplay === prevMonthAvg;
    return (
      <Stack direction="row" gap={0.5} alignItems="center" ml={1}>
        <p
          className="mb-0"
          style={{
            color: isGreater ? "#0cc437" : isEqual ? "" : "#d64343",
          }}
        >
          {percentageVar}%
        </p>
        <p className="mb-0">
          {isGreater ? (
            <ArrowUpwardIcon sx={{ color: "#0cc437" }} fontSize="20px" />
          ) : isEqual ? (
            ""
          ) : (
            <ArrowDownwardIcon color="error" fontSize="20px" />
          )}
        </p>
      </Stack>
    );
  };

  return (
    <div className="mb-4">
      <div style={{ display: "inline-flex" }} className="mb-4">
        <div>
          <Card
            style={{
              borderRadius: "15px",
              marginRight: "30px",
            }}
            className="customShadowCss"
          >
            <Card.Body>
              <Card.Title className="metricsSmallCardsCss">
                {"Total Reports"}
              </Card.Title>

              <Card.Subtitle>
                {numberOfReportsInMonth
                  ? numberOfReportsInMonth.toLocaleString("en-IN")
                  : "0"}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </div>
        <div>
          <Card style={{ borderRadius: "15px" }} className="customShadowCss">
            <Card.Body>
              <Card.Title className="metricsSmallCardsCss">
                {"Day Average"}
              </Card.Title>

              <Card.Subtitle>
                <div className="d-flex align-items-center">
                  <p className="mr-3 mb-0">
                    {averageToDisplay.toLocaleString("en-IN")}
                  </p>
                  {averageToDisplay &&
                  prevMonthData?.length &&
                  currentMonthSelection
                    ? showVariation()
                    : ""}
                </div>
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </div>
      </div>

      {/* Modal Start*/}
      <div>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title className="modalHeading">
                {"Select Custom Date"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* For selecting From Date */}

              <label className="labelTags" htmlFor="FromDate">
                From Date
              </label>
              <input
                id="FromDate"
                className="customDateTag"
                type="date"
                placeholder="DD-MM-YYYY"
                defaultValue={selectedFromDate}
                onChange={(e) => {
                  setSelectedFromDate(e.target.value);
                  setEpochFromTime(formatFromDate(e.target.value));
                  setMaxDateCalender(e.target.value);
                }}
              />
              {/* For selecting TO Date */}
              <label className="labelTags" htmlFor="ToDate">
                To Date
              </label>
              <input
                id="ToDate"
                className="customDateTag"
                type="date"
                max={maxcalenderDate}
                placeholder="DD-MM-YYYY"
                defaultValue={selectedToDate}
                onChange={(e) => {
                  setSelectedToDate(e.target.value);
                  setEpochToTime(formatToDate(e.target.value));
                }}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary btn-sm"
                onClick={async () => {
                  await customDatesDayWiseCounts();
                }}
              >
                Save Changes
              </Button>
              <Button variant="info btn-sm" onClick={handleClose}>
                {"Cancel"}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </div>

      {/* Modal End */}

      <Card>
        <Card.Body>
          <Card.Title className="metricsButtonsAlignCss">
            <div>
              {textToDisplay}
              <Spinner
                style={{
                  marginLeft: "5px",
                  display: loadingGraphValuesStatus ? "" : "none",
                }}
                id="loadingSpinnerCss"
                animation="border"
                role="status"
                size="sm"
              />
            </div>
            <div className="metricsButtonMarginsCss">
              <button
                //style={{ color: !pieDataCurrentMonth ? "#fff" : "", backgroundColor: !pieDataCurrentMonth ? "#3567D6" : "" }}
                onClick={async () => {
                  setCurrentMonthSelection(true);
                  setCustomMonthSelection(false);
                  !currentMonthSelection && (await currentMonthDayWiseCounts());
                }}
                className="metricsChartButtonCss"
                style={{
                  color: currentMonthSelection ? "#fff" : "",
                  backgroundColor: currentMonthSelection ? "#3567D6" : "",
                }}
              >
                <span className="currentMonthTextCss"></span>
              </button>
              <button
                //style={{ color: !pieDataCurrentMonth ? "#fff" : "", backgroundColor: !pieDataCurrentMonth ? "#3567D6" : "" }}
                onClick={async () => {
                  setCurrentMonthSelection(false);
                  setCustomMonthSelection(false);
                  await previousMonthDayWiseCounts();
                }}
                className="metricsChartButtonCss"
                style={{
                  color:
                    !currentMonthSelection && !customMonthSelection
                      ? "#fff"
                      : "",
                  backgroundColor:
                    !currentMonthSelection && !customMonthSelection
                      ? "#3567D6"
                      : "",
                }}
              >
                <span className="previousMonthTextCss"></span>
              </button>
              <button
                //style={{ color: pieDataCurrentMonth ? "#fff" : "", backgroundColor: pieDataCurrentMonth ? "#3567D6" : "" }}
                onClick={() => {
                  handleShow(true);
                  setCurrentMonthSelection(false);
                  setCurrentMonthSelection(false);
                }}
                className="metricsChartButtonCss"
                style={{
                  color: customMonthSelection === true ? "#fff" : "",
                  backgroundColor:
                    customMonthSelection === true ? "#3567D6" : "",
                }}
              >
                <span className="customMonthTextCss"></span>
              </button>
              <div
                onClick={downloadMetricsHandler}
                title={titleText}
                style={{
                  cursor: "pointer",
                  display:
                    loginData &&
                    loginData.role !== "LIS" &&
                    loginData.role !== "admin"
                      ? "inline"
                      : "none",
                }}
              >
                <span style={{ display: downloadStatus ? "none" : "" }}>
                  <DownloadSvgSmall />
                </span>
                <span
                  style={{
                    position: "relative",
                    display: downloadStatus ? "" : "none",
                  }}
                >
                  <Spinner animation="border" role="status" size="sm" />
                </span>
              </div>

              {/* <a
                                href="https://clientdashboard.s3.ap-south-1.amazonaws.com/reports/undefined/undefined/2022/May/undefined_May_data.xlsx"
                                download
                            >Download</a> 
                            */}
            </div>
          </Card.Title>
          <Card.Subtitle>
            {reportCount.length === 0 && !loader ? (
              <span style={{ color: "#7787bc" }}>{"No Data to display"}</span>
            ) : loader ? (
              <Spinner animation="border" size="sm" />
            ) : (
              <Chart
                options={dataToRender.options}
                series={dataToRender.series}
                type="bar"
                width={"100%"}
                height={320}
              />
            )}
          </Card.Subtitle>
          <div className="columnBarFooterItemsCss">
            <Card.Link
              className="dayWiseFooterCss"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <CalenderSvg />
              {"Day wise Report Count"}
            </Card.Link>
            <Card.Link
              className="reportsGeneratedFooterCss"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <ReportsGeneratedSvgIcon />
              {"Reports Generated"}
            </Card.Link>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default MetricsSection;
