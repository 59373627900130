import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import axios from "axios";
import AlertTemplate from "react-alert-template-basic";
import { positions, Provider } from "react-alert";
import { useAlert } from "react-alert";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Stack,
  Chip,
  OutlinedInput,
  useTheme,
} from "@mui/material";

const SignUpLab = () => {
  // react alert template options
  const options = {
    timeout: 5000,
    position: "bottom left",
  };
  const theme = useTheme();
  const alert = useAlert();

  const [typeOfNewClient, setTypeOfNewClient] = useState("");
  const [noticeChangeInValue, setNoticeChangeInValue] = useState(true);
  const [expectedJson, setExpectedJson] = useState("");

  const [userId, setUserId] = useState("");
  const [clientId, setClientId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [org, setOrg] = useState("");
  const [center, setCenter] = useState("");
  const [reportsSubscribed, setReportsSubscribed] = useState(0);
  const [subscriptionType, setSubscriptionType] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("on hold");
  const [showBilling, setShowBilling] = useState(true);
  const [logoUrl, setLogoUrl] = useState(
    "https://res.cloudinary.com/nithish0504/image/upload/v1652856436/niroggyan-logo_pc2fef.svg"
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mailID, setMailId] = useState("");
  const [dashboardAccess, setDashboardAccess] = useState(true);
  const [portalAccess, setPortalAccess] = useState(false);
  const [smartReportAccess, setsmartReportAccess] = useState(false);
  const [bodySummary, setBodySummary] = useState(false);
  const [reportSummary, setReportSummary] = useState(false);
  const [addtionalTestRec, setaddtionalTestRec] = useState(false);
  const [reportType, setReportType] = useState("");
  const [selectedAddons, setSelectedAddons] = useState([]);

  const reportTypeMap = {
    compact: "Compact",
    dynamic: "Personal",
    advanced: "Total",
  };

  useEffect(() => {
    let currentLocation = window.location.href;
    let clientType = currentLocation.substring(
      currentLocation.lastIndexOf("/") + 1
    );
    setTypeOfNewClient(clientType);
  }, []);

  const idToken = localStorage.getItem("idTokenBilling");
  async function createStep(stepData) {
    try {
      const apiEndpoint =
        "https://api.niroggyan.com/onboarding/status/createstep";

      const response = await axios.post(apiEndpoint, stepData, {
        headers: {
          Authorization: `${idToken}`,
        },
      });

      if (response.status === 200) {
        console.log(`Step '${stepData.step_name}' created successfully.`);
      } else {
        console.error(
          `Failed to create step '${stepData.step_name}' with status code ${response.status}.`
        );
      }
    } catch (error) {
      console.error(`Error creating step: ${error.message}`);
    }
  }

  async function createInitialSteps() {
    var currentDate = new Date();
    var firstDeadlineDate = new Date(currentDate);
    firstDeadlineDate.setDate(currentDate.getDate() + 3);
    var secondDeadlineDate = new Date(firstDeadlineDate);
    secondDeadlineDate.setDate(firstDeadlineDate.getDate() + 3);
    var thirdDeadlineDate = new Date(secondDeadlineDate);
    thirdDeadlineDate.setDate(secondDeadlineDate.getDate() + 2);
    var fourthDeadlineDate = new Date(thirdDeadlineDate);
    fourthDeadlineDate.setDate(thirdDeadlineDate.getDate() + 2);

    const stepsData = [
      {
        step_name: "Requirements",
        clientId: clientId,
        status: "notStarted",
        created_at: currentDate,
        completed_at: null,
        completed_by: null,
        dependencyArray: ["nithish@niroggyan.com", "kiran@niroggyan.com"],
        deadline_date: firstDeadlineDate,
        substeps: [
          {
            substep_name: "JSON of a comprehensive package",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Lab report of the same",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Excel with parameter ids",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Header and footer if not sending in JSON",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Doctor signature if not sending in JSON",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Accreditation logos if not sending in JSON",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Cover page in jpg or png format in A4 size",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
        ],
      },
      {
        step_name: "Mapping",
        clientId: clientId,
        status: "notStarted",
        created_at: firstDeadlineDate,
        completed_at: null,
        completed_by: null,
        dependencyArray: ["nithish@niroggyan.com", "kiran@niroggyan.com"],
        deadline_date: secondDeadlineDate,
        substeps: [
          {
            substep_name: "Biomarker Mapping",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Profile Mapping",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Range Mapping",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Report Configurations",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
        ],
      },
      {
        step_name: "Internal Testing",
        clientId: clientId,
        status: "notStarted",
        created_at: secondDeadlineDate,
        completed_at: null,
        completed_by: null,
        dependencyArray: ["nithish@niroggyan.com", "kiran@niroggyan.com"],
        deadline_date: thirdDeadlineDate,
        substeps: [
          {
            substep_name:
              "Header, Footer, Pateint details, Doctor signatures, Accreditation logos",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Parameter names",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Units & Result values",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Method & Ranges",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Result indicator ( normal / abnormal )          ",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Profile grouping",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Report design",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Repetitive / Wrong parameters",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Extra pages",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
        ],
      },
      {
        step_name: "Client Side Testing",
        clientId: clientId,
        status: "notStarted",
        created_at: thirdDeadlineDate,
        completed_at: null,
        completed_by: null,
        dependencyArray: ["nithish@niroggyan.com", "kiran@niroggyan.com"],
        deadline_date: fourthDeadlineDate,
        substeps: [
          {
            substep_name:
              "Header, Footer, Pateint details, Doctor signatures, Accreditation logos",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Parameter names",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Units & Result values",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Method & Ranges",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Result indicator ( normal / abnormal )          ",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Profile grouping",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Report design",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Repetitive / Wrong parameters",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
          {
            substep_name: "Extra pages",
            is_substep_completed: false,
            completed_by: null,
            completed_at: null,
          },
        ],
      },
    ];

    for (const stepData of stepsData) {
      await createStep(stepData);
    }
  }

  const handleRequiredJson = async () => {
    let theJson = {
      userId: userId,
      center: center,
      clientId: clientId,
      customerName: displayName,
      displayName: displayName,
      endDate: endDate + " 23:59:59.000",
      logoSrc: logoUrl,
      org: org,
      password: password,
      reportsSubscribed: parseInt(reportsSubscribed),
      role: "lab",
      showBilling: showBilling === "true" ? true : false,
      startDate: startDate + " 00:00:00.000",
      subscription: subscriptionType,
      subscriptionStatus: subscriptionStatus,
      accessTo: {
        dashBoard: dashboardAccess,
        portal: portalAccess,
        smartReport: smartReportAccess,
      },
      notifications: {
        lastNotifiedAt: 0,
        notifiedAt: 0,
        mailIds: mailID.includes(",") ? mailID.split(",") : [mailID],
        status: "new",
      },
      reportSubscription: {
        reportType: reportType,
        addOns: {
          bodySummary: bodySummary,
          reportSummary: reportSummary,
          additionalTestRecommendation: addtionalTestRec,
        },
      },
      reportAddons: selectedAddons,
    };
    // console.log(theJson);

    await setExpectedJson(theJson);
    await setNoticeChangeInValue(false);
  };

  const handleFormSubmission = (e) => {
    e.preventDefault();
    if (
      password === confirmPassword &&
      password !== "" &&
      confirmPassword !== ""
    ) {
      sendDataToBackend();
    } else {
      alert.error("Passwords do not match!");
    }
  };

  const emailHtmlBody = (jsonObj) => {
    let html = `
    <!DOCTYPE html>
    <html>
    <head>
      <title>Client Signup</title>
      <style>
       body {
        color: black;
       }
      </style>
    </head>
    <body>
      <p>A new client has signed up. Please find the details below.</p>
      <ul>
       <li>Client Name: ${jsonObj.clientName}</li>
       <li>Plan Type: ${jsonObj.planType}</li>
        <li>Report Type: ${jsonObj.reportType}</li>
        <li>Add-ons: ${jsonObj.addOns.join(", ")}</li>

      </ul>
      
    </body>
    </html>
`;
    return html;
  };

  //   const signUpHtmlBody = (jsonObj) => {
  //     let html = `
  //     <!DOCTYPE html>
  //     <html>
  //     <head>
  //       <title>Client Signup</title>
  //       <style>
  //        body {
  //         color: black;
  //        }
  //       </style>
  //     </head>
  //     <body>
  //       <p>Dear ${jsonObj.clientName}</p>
  //        <p>Greetings from NirogGyan!</p>
  //       <p>We’re extremely pleased that you’ve chosen us for generating modern Smart Reports to empower and engage your patients. Your NirogGyan account has been created, and you can access the portal here: <a href="https://www.client-dashboard.niroggyan.com">https://www.client-dashboard.niroggyan.com</a>.
  //       </p>
  //       <p>Thanks,</p>
  //       <p>NirogGyan Team</p>

  //     </body>
  //     </html>
  // `;
  //     return html;
  //   };

  const sendEmailNotification = async () => {
    const signUpDetails = {
      clientName: displayName,
      planType: subscriptionType,
      reportType: reportTypeMap[reportType],
      addOns: selectedAddons,
    };

    let maildata = {
      fromEmail: ["reports@niroggyan.com"],
      toEmail: [
        // "shweta@niroggyan.com",
        // "joyneel@niroggyan.com",
        "rajeev@niroggyan.com",
        "tech@niroggyan.com",
      ],
      // cc: ["nithish@niroggyan.com", "kiran@niroggyan.com"],
      attachments: [],
      withAttachments: "false",
      bodyType: "html",
      emailBody: emailHtmlBody(signUpDetails),
      subject: "New Client Signup",
    };
    const mailApi =
      "https://917sfoy1xc.execute-api.ap-south-1.amazonaws.com/Testing/notifications/email";

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(maildata),
    };
    try {
      const response = await fetch(mailApi, options);
      const data = await response.json();
      console.log("mail response", data);
    } catch (e) {
      console.log("error", e);
    }
  };

  const sendDataToBackend = async () => {
    var config = {
      method: "POST",
      // url: "",
      url: "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/subscription/signup",
      headers: {
        "Content-Type": "application/json",
      },
      data: expectedJson,
      //data: dummyData
    };

    console.log("what im sending:", expectedJson);

    try {
      const axiosData = await axios(config);
      console.log("inside axiosdata ===> \n\n", axiosData);
      if (axiosData.data.insertStatus) {
        alert.success("Signup Successfull");
        createInitialSteps();
        sendEmailNotification();
      } else {
        alert.error(axiosData.data.message);
      }
    } catch (error) {
      console.log("Error for Labs:", error);
      alert.error("Signup Failed!");
    }
  };

  const addonElements = [
    "Cover Page",
    "Body Summary",
    "Report Summary",
    "Test Recommendation",
    "Trend Report",
    "Viz App",
    "Smart Video Report",
    "Marketing Page",
  ];

  const ITEM_HEIGHT = 42;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAddons(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <>
      <Provider template={AlertTemplate} {...options}>
        <div
          style={{ color: "#3c72eb", textAlign: "center", fontWeight: "bold" }}
        >
          {"Signing Up"} {typeOfNewClient.toUpperCase()}
        </div>
        <hr />

        <Form onSubmit={handleFormSubmission}>
          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon1">
                {"User ID"}
              </InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setUserId(e.target.value);
                }}
                placeholder="user id"
                aria-label="userid"
                aria-describedby="basic-addon1"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon2">
                {"Client Id"}
              </InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setClientId(e.target.value);
                }}
                placeholder="client id"
                aria-label="clientId"
                aria-describedby="basic-addon2"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon3">
                {"Display Name"}
              </InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setDisplayName(e.target.value);
                }}
                placeholder="display name"
                aria-label="displayName"
                aria-describedby="basic-addon3"
              />
            </InputGroup>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon4">
                {"Org"}
              </InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setOrg(e.target.value);
                }}
                placeholder="org"
                aria-label="org"
                aria-describedby="basic-addon4"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon5">
                {"Center"}
              </InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setCenter(e.target.value);
                }}
                placeholder="center"
                aria-label="center"
                aria-describedby="basic-addon5"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon6">
                {"Reports Subscribed"}
              </InputGroup.Text>
              <Form.Control
                // required
                type="number"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setReportsSubscribed(e.target.value);
                }}
                placeholder="reports"
                aria-label="reports"
                aria-describedby="basic-addon6"
              />
            </InputGroup>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon16"
              >
                {"DashBoard Access"}
              </InputGroup.Text>
              <Form.Select
                aria-label="DashBoard Access"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  if (e.target.value === "true") {
                    setDashboardAccess(true);
                  } else {
                    setDashboardAccess(false);
                  }
                }}
              >
                <option value={true} selected={dashboardAccess === true}>
                  {"YES"}
                </option>
                <option value={false} selected={dashboardAccess === false}>
                  {"No"}
                </option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon17"
              >
                {"Portal Access"}
              </InputGroup.Text>
              <Form.Select
                aria-label="Portal Access"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  if (e.target.value === "true") {
                    setPortalAccess(true);
                  } else {
                    setPortalAccess(false);
                  }
                }}
              >
                <option value={true} selected={portalAccess === true}>
                  {"YES"}
                </option>
                <option value={false} selected={portalAccess === false}>
                  {"No"}
                </option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon18"
              >
                {"Smart Report Access"}
              </InputGroup.Text>
              <Form.Select
                aria-label="Smart Report Access"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  if (e.target.value === "true") {
                    setsmartReportAccess(true);
                  } else {
                    setsmartReportAccess(false);
                  }
                }}
              >
                <option value={true} selected={smartReportAccess === true}>
                  {"YES"}
                </option>
                <option value={false} selected={smartReportAccess === false}>
                  {"No"}
                </option>
              </Form.Select>
            </InputGroup>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon19"
              >
                {"Body Summary"}
              </InputGroup.Text>
              <Form.Select
                aria-label="Body Summary"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  if (e.target.value === "true") {
                    setBodySummary(true);
                  } else {
                    setBodySummary(false);
                  }
                }}
              >
                <option value={true} selected={bodySummary === true}>
                  {"YES"}
                </option>
                <option value={false} selected={bodySummary === false}>
                  {"No"}
                </option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon20"
              >
                {"Report Summary"}
              </InputGroup.Text>
              <Form.Select
                aria-label="Report Summary"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  if (e.target.value === "true") {
                    setReportSummary(true);
                  } else {
                    setReportSummary(false);
                  }
                }}
              >
                <option value={true} selected={reportSummary === true}>
                  {"YES"}
                </option>
                <option value={false} selected={reportSummary === false}>
                  {"No"}
                </option>
              </Form.Select>
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon21"
              >
                {"Additional Test Recommendation"}
              </InputGroup.Text>
              <Form.Select
                id="basic-addon21"
                aria-label="basic-addon21"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  console.log(e.target.value, Boolean(e.target.value));
                  if (e.target.value === "true") {
                    setaddtionalTestRec(true);
                  } else {
                    setaddtionalTestRec(false);
                  }
                }}
              >
                <option value={true} selected={addtionalTestRec === true}>
                  {"YES"}
                </option>
                <option value={false} selected={addtionalTestRec === false}>
                  {"No"}
                </option>
              </Form.Select>
            </InputGroup>
          </div>
          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon22"
                title={"Enter Multiple mail ID's seperated by comma"}
              >
                {"Notification Mail Id"}
              </InputGroup.Text>
              <Form.Control
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setMailId(e.target.value);
                }}
                placeholder="Mail ID"
                aria-label="Mail ID"
                aria-describedby="basic-addon22"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon22"
              >
                {"Report Type"}
              </InputGroup.Text>
              <Form.Select
                aria-label="Report Type"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setReportType(e.target.value);
                }}
              >
                <option value={""} disabled selected>
                  {"Select Report Type"}
                </option>
                <option value={"dynamic"} selected={reportType === "dynamic"}>
                  {"Personal"}
                </option>
                <option value={"advanced"} selected={reportType === "advanced"}>
                  {"Total"}
                </option>
                <option value={"compact"} selected={reportType === "compact"}>
                  {"Compact"}
                </option>
              </Form.Select>
            </InputGroup>
          </div>

          <div className="mt-0" style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon7">
                {"Subscription"}
              </InputGroup.Text>
              <Form.Control
                required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setSubscriptionType(e.target.value);
                }}
                placeholder="Starter Plan/Growth Plan/Pro Plan"
                aria-label="subscription"
                aria-describedby="basic-addon7"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon12"
              >
                {"Logo Url"}
              </InputGroup.Text>
              <Form.Control
                type="text"
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setLogoUrl(e.target.value);
                }}
                placeholder="https://......"
                aria-label="logoUrl"
                defaultValue={logoUrl}
                aria-describedby="basic-addon12"
              />
            </InputGroup>
          </div>

          {/* add ons  */}
          <div>
            <Stack direction="row" alignItems="center" mb={2}>
              {/* <Typography sx={{border:""}}>Add Ons</Typography> */}
              <InputGroup.Text
                style={{ fontWeight: "bold", alignSelf: "stretch" }}
                id="basic-addon22"
              >
                Report Add-ons
              </InputGroup.Text>
              <FormControl
                size="small"
                sx={{ flexGrow: "1", minWidth: "300px" }}
              >
                {/* <InputLabel id="demo-multiple-chip-label"></InputLabel> */}
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  sx={{ background: "#fff" }}
                  multiple
                  value={selectedAddons}
                  onChange={handleChange}
                  input={<OutlinedInput id="select-multiple-chip" label="" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                      {selected.map((value) => (
                        <Chip key={value} label={value} />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {addonElements.map((name) => (
                    <MenuItem
                      key={name}
                      value={name}
                      style={getStyles(name, selectedAddons, theme)}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </div>
          {/* add ons  */}

          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon8">
                {"Starting Date"}
              </InputGroup.Text>
              <Form.Control
                // required
                type="date"
                defaultValue={startDate}
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setStartDate(e.target.value);
                }}
                placeholder="startDate"
                aria-describedby="basic-addon8"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon9">
                {"Ending Date"}
              </InputGroup.Text>
              <Form.Control
                // required
                type="date"
                defaultValue={endDate}
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setEndDate(e.target.value);
                }}
                placeholder="endDate"
                aria-describedby="basic-addon9"
              />
            </InputGroup>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon10"
              >
                {"Subscription Status"}
              </InputGroup.Text>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setSubscriptionStatus(e.target.value);
                }}
              >
                <option value="live" selected={subscriptionStatus === "live"}>
                  {"LIVE"}
                </option>
                <option
                  value="on hold"
                  selected={subscriptionStatus === "on hold"}
                >
                  {"HOLD"}
                </option>
              </Form.Select>
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon11"
              >
                {"Show Billing"}
              </InputGroup.Text>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setShowBilling(e.target.value);
                }}
              >
                <option value="true" selected={showBilling}>
                  {"YES"}
                </option>
                <option value="false" selected={!showBilling}>
                  {"NO"}
                </option>
              </Form.Select>
            </InputGroup>
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon13"
              >
                {"Password"}
              </InputGroup.Text>
              <Form.Control
                type="password"
                defaultValue={password}
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setPassword(e.target.value);
                }}
                aria-label="password"
                placeholder="password"
                aria-describedby="basic-addon13"
              />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text
                style={{ fontWeight: "bold" }}
                id="basic-addon14"
              >
                {"Confirm Password"}
              </InputGroup.Text>
              <Form.Control
                type="password"
                defaultValue={confirmPassword}
                // required
                onChange={(e) => {
                  setNoticeChangeInValue(true);
                  setConfirmPassword(e.target.value);
                }}
                aria-label="confirmpassword"
                placeholder="retype password"
                aria-describedby="basic-addon14"
              />
            </InputGroup>
          </div>

          <div
            className="my-3"
            style={{ display: "flex", gap: "15px", justifyContent: "center" }}
          >
            <button
              className="btn btn-info btn-sm"
              type="button"
              onClick={handleRequiredJson}
            >
              Confirm Changes
            </button>
            <button
              disabled={noticeChangeInValue}
              className="btn btn-sm"
              style={{ backgroundColor: "#3c72eb", color: "#fff" }}
              type="submit"
            >
              Submit
            </button>
          </div>
        </Form>
      </Provider>
    </>
  );
};

export default SignUpLab;
