import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Checkbox,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import axios from "axios";
import Swal from "sweetalert2";

const UpdateStepStatusPopup = ({ stepData, open, onClose, idToken, getStatusAPI }) => {
  const [updatedData, setUpdatedData] = useState({
    step_name: stepData.step_name,
    deadline_date: stepData.deadline_date,
    status: stepData.status,
    completed_by: stepData.completed_by,
    dependencyArray: stepData.dependencyArray || [],
    substeps: stepData.substeps.map((substep) => ({
      substep_name: substep.substep_name,
      is_substep_completed: substep.is_substep_completed,
      completed_at: substep.is_substep_completed ? new Date() : null,
      completed_by: substep.completed_by,
    })),
  });


  const handleInputChange = (field, value) => {
    if (field === "dependencyArray") {
      const dependencyArray = value.split(",").map((email) => email.trim());
      setUpdatedData({ ...updatedData, [field]: dependencyArray });
    } else {
      setUpdatedData({ ...updatedData, [field]: value });
    }
  };

  const handleSubstepChange = (index, field, value) => {
    const updatedSubsteps = [...updatedData.substeps];
    if (field === "is_substep_completed") {
      updatedSubsteps[index].completed_at = value ? new Date() : null;
    }
    updatedSubsteps[index][field] = value;
    setUpdatedData({ ...updatedData, substeps: updatedSubsteps });
  };

  const handleSubstepNameChange = (index, value) => {
    const updatedSubsteps = [...updatedData.substeps];
    updatedSubsteps[index].substep_name = value;
    setUpdatedData({ ...updatedData, substeps: updatedSubsteps });
  };

  const handleSubmit = () => {
    const updatedStepData = {
      ...updatedData,
      created_at: stepData.created_at,
      clientId: stepData.clientId,
      dependencyArray: updatedData.dependencyArray,
      completed_at:
        updatedData.status === "completed" ? new Date() : stepData.completed_at,
    };

    axios
      .post(
        `https://api.niroggyan.com/onboarding/status/updatestep?id=${stepData._id}`,
        updatedStepData,
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      )
      .then((response) => {
        response.status === 200 &&
          Swal.fire({
            icon: "success",
            text: "Step updated Successfully",
            showConfirmButton: false,
            timer: 2000,
          });
        console.log("Update successful:", response.data);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          text: error,
          showConfirmButton: false,
          timer: 2000,
        });
        console.error("Error updating status:", error);
      })
      .finally(() => {
        getStatusAPI();
        onClose();
      });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Step Status</DialogTitle>
      <DialogContent>
        <Stack gap={2} margin={"20px"}>
          <TextField
            label="Step Name"
            variant="outlined"
            fullWidth
            value={updatedData.step_name}
            onChange={(e) => handleInputChange("step_name", e.target.value)}
          />
          <TextField
            label="Deadline"
            variant="outlined"
            fullWidth
            type="date"
            value={updatedData?.deadline_date?.split("T")[0]}
            onChange={(e) => handleInputChange("deadline_date", e.target.value)}
          />
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              value={updatedData.status}
              onChange={(e) => handleInputChange("status", e.target.value)}
            >
              <MenuItem value="inProgress">In Progress</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
              <MenuItem value="notStarted">Not Started</MenuItem>
            </Select>
          </FormControl>
          {updatedData.status === "completed" && (
            <FormControl>
              <InputLabel>Completed by</InputLabel>
              <Select
                sx={{ width: "200px" }}
                value={updatedData.completed_by}
                onChange={(e) =>
                  handleInputChange("completed_by", e.target.value)
                }
              >
                {stepData.dependencyArray.map((email) => (
                  <MenuItem key={email} value={email}>
                    {email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <TextField
            label="Dependency Emails (Comma-separated)"
            variant="outlined"
            fullWidth
            value={updatedData.dependencyArray.join(",")}
            onChange={(e) => {
              const emailArray = e.target.value
                .split(",")
                .map((email) => email.trim());
              handleInputChange("dependencyArray", emailArray);
            }}
          />
          <hr />
          <h6>Edit Substeps</h6>
          {updatedData.substeps.map((substep, index) => (
            <div key={index}>
              <Stack direction="column" gap={2}>
                <TextField
                  label="Substep Name"
                  variant="outlined"
                  fullWidth
                  value={substep.substep_name}
                  onChange={(e) =>
                    handleSubstepNameChange(index, e.target.value)
                  }
                />
                <Stack direction="row" justifyContent="space-between">
                  {substep.is_substep_completed && (
                    <FormControl>
                      <InputLabel>Completed by</InputLabel>
                      <Select
                        sx={{ width: "200px" }}
                        value={substep.completed_by}
                        onChange={(e) =>
                          handleSubstepChange(
                            index,
                            "completed_by",
                            e.target.value
                          )
                        }
                      >
                        {stepData.dependencyArray.map((email) => (
                          <MenuItem key={email} value={email}>
                            {email}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  <Checkbox
                    sx={{ height: "fit-content" }}
                    checked={substep.is_substep_completed}
                    onChange={(e) =>
                      handleSubstepChange(
                        index,
                        "is_substep_completed",
                        e.target.checked
                      )
                    }
                  />
                </Stack>
              </Stack>
            </div>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStepStatusPopup;
