import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import '../../css/planDetailsSectionCss.css';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
// import axios from 'axios';
// import { FetchPercentage } from '../FetchPercentage';
import { Spinner } from 'react-bootstrap';

const PlanDetailsSection = ({ reportsConsumed }) => {
  const [percentage, setPercentage] = useState('0');
  const [loginData, setLoginData] = useState('');
  const [fetchedHomeData, setFetchedHomeData] = useState('');
  const [clientData, setClientData] = useState('');

  // const [orgName, setOrgName] = useState('');
  // const [centerName, setCenterName] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  // const [statesUpdated, setStatesUpdated] = useState(false);
  // const [numberOfReportsConsumed, setNumberOfReportsConsumed] =
  //   useState(reportsConsumed);

  const [reportsSubscribedInfo, setReportsSubscribedInfo] = useState('0');
  const [reRenderComponent, setReRenderComponent] = useState(false);

  const [dbLoader, setDbLoader] = useState(false);
  const dataFetchedRef = useRef(false);

  // console.log(loader, 'loder');

  window.addEventListener('storage', e => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  // just for little animation in circular progress bar
  // useEffect(() => {
  //     setTimeout(() => {
  //         setPercentage("60")
  //     }, 1200)
  // }, [])

  // fetching loginDetails
  useEffect(() => {
    //console.log("calling plan details section")
    const loginInfo = JSON.parse(localStorage.getItem('loginDetails'));
    let homepageData = JSON.parse(localStorage.getItem('homepageData'));
    let clientInfo = JSON.parse(localStorage.getItem('clientPreference'));

    if (loginInfo) {
      setLoginData(loginInfo);
      setClientData(clientInfo);
      setReportsSubscribedInfo(loginInfo.reportsSubscribed);
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      // fetchReportCounts();
    }

    if (homepageData) {
      setFetchedHomeData(homepageData);
    }
  }, [reRenderComponent]);

  // calculate percentage
  useEffect(() => {
    const thePercentage = Math.round(
      (reportsConsumed / reportsSubscribedInfo) * 100
    );
    // set percentage

    if (thePercentage > 100) {
      setPercentage(100);
    } else {
      setPercentage(thePercentage);
    }
  }, [percentage]);

  // console.log(FetchPercentage())
  //console.log("fetchedHomeData\n", fetchedHomeData)

  return (
    <>
      <div
        className='planScreenResolutionCss my-4'
        style={{
          display:
            loginData && loginData.showBilling === true && clientData === 'none'
              ? ''
              : 'none',
        }}
      >
        <div className='planScreenLeftContentCss'>
          <Card>
            <Card.Body>
              <Card.Title>{'Plan Detail'}</Card.Title>
              <div>
                <Card.Subtitle className='planDetailsCardSubtitleCss'>
                  <div className='circularProgressBarDimensionsCss'>
                    <CircularProgressbar
                      value={percentage}
                      text={`${percentage}%`}
                      styles={buildStyles({
                        textSize: '16px',
                        pathTransitionDuration: 0.5, // How long animation takes to go from one percentage to another, in seconds
                        // pathColor: `rgb(53,103,214)`, ${percentage / 100})`,
                        pathColor:
                          percentage < 80
                            ? 'rgb(53,103,214)'
                            : 'rgb(255,77,77)',
                        textColor: '#170C6B',
                        trailColor: '#C7CEDD',
                        backgroundColor: '#3567D6',
                      })}
                    />
                  </div>
                </Card.Subtitle>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '10px',
                  }}
                >
                  <span style={{ color: '#7787bc', marginRight: '3px' }}>
                    {'Total Reports: '}
                  </span>
                  <span style={{ fontWeight: '550' }}>
                    {loginData ? loginData.reportsSubscribed : ''}
                  </span>
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '5px',
                  }}
                >
                  <span style={{ color: '#7787bc', marginRight: '3px' }}>
                    {'Number of Report Generated: '}
                  </span>
                  <span style={{ fontWeight: '550' }}>
                    {percentage}
                    {'%'}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className='planScreenRightContentCss'>
          <Row xs={1} md={2} className='g-4'>
            <Col>
              <Card className='planDetailsRightCardCss'>
                <Card.Body className='planDetailsRightCardBodyCss'>
                  <Card.Title className='totalReportsTitleCss'>
                    {'Total Reports'}
                  </Card.Title>
                  <Card.Text className='totalReportsNumberCss'>
                    {loginData ? loginData.reportsSubscribed : ''}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className='planDetailsRightCardCss'>
                <Card.Body className='planDetailsRightCardBodyCss'>
                  <Card.Title className='totalReportsTitleCss'>
                    {'Consumed'}
                  </Card.Title>

                  <Card.Text className='totalReportsNumberCss'>
                    {reportsConsumed}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className='planDetailsRightCardCss'>
                <Card.Body className='planDetailsRightCardBodyCss'>
                  <Card.Title className='totalReportsTitleCss'>
                    {'Remaining'}
                  </Card.Title>
                  <Card.Text className='totalReportsNumberCss'>
                    {loginData && reportsConsumed !== '0'
                      ? loginData.reportsSubscribed - reportsConsumed
                      : '0'}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>

            <Col>
              <Card className='planDetailsRightCardCss'>
                <Card.Body className='planDetailsRightCardBodyCss'>
                  <Card.Title className='totalReportsTitleCss'>
                    {'Percentage'}
                  </Card.Title>
                  <Card.Text className='totalReportsNumberCss'>
                    {loginData ? (
                      Math.round(
                        (reportsConsumed / loginData.reportsSubscribed) * 100
                      ) > 100 ? (
                        <span style={{ fontSize: '20px' }}>
                          {'Over Consumed'}
                        </span>
                      ) : (
                        Math.round(
                          (reportsConsumed / loginData.reportsSubscribed) * 100
                        )
                      )
                    ) : (
                      ''
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PlanDetailsSection;
