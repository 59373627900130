import React from 'react'

const ReportsGeneratedSvgIcon = () => {
    return (
        <>
            <svg style={{ position: "relative", top: "-1.5px", right: "5px" }} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="9" cy="9" r="9" fill="#FF6384" />
            </svg>
        </>
    )
}

export default ReportsGeneratedSvgIcon