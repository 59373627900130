import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
// import { useNavigate } from 'react-router-dom';
import { Modal, Spinner } from "react-bootstrap";
import Subscription from "./Subscription";
import { useAlert } from "react-alert";
import "../../css/tableComponentCss.css";

const Styles = styled.div`
  table {
    border-spacing: 0;
    border: 1px solid rgba(221, 224, 225, 1);
    height: 60vh;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    thead {
      position: sticky;
      top: 0;
      background-color: white;
      margin-top: -10px;
      border: 1px solid rgba(221, 224, 225, 1);
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid rgba(221, 224, 225, 1);
      border-right: 1px solid rgba(221, 224, 225, 1);
      width: "20%";

      :last-child {
        border-right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    .mobile-column {
      display: none;
    }
    th {
      font-size: 14px;
    }
  }
`;

function Table({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [
            {
              id: "subscriptionStatus",
              desc: false,
            },
          ],
          // Don't delete this. This is important for Loader
          hiddenColumns: ["loader", "vizAppLoader", "prevCountLoader"],
        },
      },
      useSortBy
    );

  // We don't want to render all 2000 rows for this example, so cap
  // it at 20 for this use case
  // const [rowIndex, setRowIndex] = useState(0);
  // const rowsToDisplay = 6;
  // const firstPageRows = rows.slice(
  //   rowIndex * rowsToDisplay,
  //   (rowIndex + 1) * rowsToDisplay
  // );

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th
                  className={column.showMobile ? "" : "mobile-column"}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  {[
                    "Subscription End Date",
                    "Client Name",
                    "Client ID",
                    "Organization",
                    "Subscription Status",
                  ].includes(column.Header) && (
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          " 🔽"
                        ) : (
                          " 🔼"
                        )
                      ) : (
                        <i
                          class="fas fa-sort"
                          style={{ color: "#00a6ed", marginLeft: "10px" }}
                        ></i>
                      )}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      className={
                        cell.column.showMobile
                          ? "mobile-table-cell"
                          : "mobile-column"
                      }
                      {...cell.getCellProps()}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <br />
      {/* <button
        disabled={rowIndex === 0 ? true : false}
        onClick={() => {
          setRowIndex(prevIndex => {
            if (prevIndex > 0) {
              return prevIndex - 1;
            } else {
              return prevIndex;
            }
          });
        }}
        className='btn btn-info'
      >
        Prev
      </button>
      <button
        className='btn btn-info mx-2'
        disabled={
          rowIndex === Math.floor(rows.length / rowsToDisplay) ? true : false
        }
        onClick={() => {
          setRowIndex(prevIndex => {
            if (prevIndex < rows.length - 1) {
              return prevIndex + 1;
            }
          });
        }}
      >
        Next
      </button>

      <span>
        Page {rowIndex + 1} of {Math.floor(rows.length / rowsToDisplay) + 1}
      </span> */}
    </>
  );
}
const ClientsTableView = () => {
  const [dataForTables, setDataForTables] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);

  const [isModalClosed, setIsModalClosed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const alert = useAlert();

  const handleClose = () => {
    setModalOpen(false);
    setIsModalClosed((prevState) => !prevState);
  };
  const handleShow = () => setModalOpen(true);

  useEffect(() => {
    (async function () {
      try {
        getAllClients();
        setPageLoader(false);
      } catch (e) {
        alert.error(e.message);
        setPageLoader(false);
      }
    })();
  }, [isModalClosed]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAllClients = async () => {
    const api =
      "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/subscription/labusers";
    const configBody = {
      method: "get",
      url: api,
    };
    const response = await axios(configBody);

    setDataForTables(response.data.data);
  };

  const isValidSubscriptionEndDate = (subscriptionDate) => {
    const date = new Date();
    return new Date(subscriptionDate) > date;
  };

  const getClientCounts = async (
    clientDetails,
    productType,
    isPreviousCycle = false
  ) => {
    // Used for ID Token
    const idToken = localStorage.getItem("idTokenBilling");

    // For Getting User ID for sending in the API Call
    const clientData = JSON.parse(
      localStorage.getItem("loginDetails")
    ).clientData;
    const selectedClientDetails = clientData.filter(
      (each) =>
        each.org === clientDetails.org && each.center === clientDetails.center
    )[0];
    const userId = selectedClientDetails.userId;

    setDataForTables((existingData) => {
      return existingData.map((eachData) => {
        if (
          eachData.org === clientDetails.org &&
          eachData.center === clientDetails.center
        ) {
          return productType === "smartreport"
            ? isPreviousCycle
              ? { ...eachData, prevCountLoader: true }
              : { ...eachData, loader: true }
            : { ...eachData, vizAppLoader: true };
        }
        return { ...eachData };
      });
    });

    // Configuration for API Call
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "get",
      url: `https://api.niroggyan.com/billing/${productType}/counts?query_on=counts&query_by=subscription&userId=${userId}&cycle=${
        isPreviousCycle ? "previous" : ""
      }`,
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };

    try {
      const response = await axios(configBody);
      if (response.data.isSuccess) {
        setDataForTables((existingData) => {
          return existingData.map((eachData) => {
            if (
              eachData.org === clientDetails.org &&
              eachData.center === clientDetails.center
            ) {
              return productType === "smartreport"
                ? isPreviousCycle
                  ? { ...eachData, previousCount: response.data.data[0].count }
                  : { ...eachData, count: response.data.data[0].count }
                : { ...eachData, vizAppCount: response.data.data[0].count };
            }
            return { ...eachData };
          });
        });
      } else {
        throw new Error();
      }
    } catch (e) {
      console.log("error in fetching Counts");
      window.alert("Error in fetching Counts");
      setDataForTables((existingData) => {
        return existingData.map((eachData) => {
          if (
            eachData.org === clientDetails.org &&
            eachData.center === clientDetails.center
          ) {
            return productType === "smartreport"
              ? isPreviousCycle
                ? { ...eachData, prevCountLoader: false }
                : { ...eachData, loader: false }
              : { ...eachData, vizAppLoader: false };
          }
          return { ...eachData };
        });
      });
    }
    setDataForTables((existingData) => {
      return existingData.map((eachData) => {
        if (
          eachData.org === clientDetails.org &&
          eachData.center === clientDetails.center
        ) {
          return productType === "smartreport"
            ? isPreviousCycle
              ? { ...eachData, prevCountLoader: false }
              : { ...eachData, loader: false }
            : { ...eachData, vizAppLoader: false };
        }
        return { ...eachData };
      });
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Client Name",
        accessor: "displayName",
        showMobile: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return (
            <div
              style={{
                width: isMobile ? "60px" : "120px",
                wordWrap: "break-word",
                fontSize: isMobile ? "14px" : "16px",
              }}
            >
              {values.displayName}
            </div>
          );
        },
      },
      {
        Header: "Org",
        accessor: "org",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return (
            <div style={{ width: "80px", wordWrap: "break-word" }}>
              {values.org}
            </div>
          );
        },
      },
      {
        Header: "Center",
        accessor: "center",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return (
            <div style={{ width: "80px", wordWrap: "break-word" }}>
              {values.center}
            </div>
          );
        },
      },
      {
        Header: "Mail ID",
        accessor: "mailIds",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return (
            <div style={{ width: "190px", wordWrap: "break-word" }}>
              {values.mailIds.join(",")}
            </div>
          );
        },
      },
      {
        Header: "Reports Subscribed",
        accessor: "reportsSubscribed",
        disableSortBy: true,
      },
      // For Creating Loader
      {
        Header: "pastCount-loader",
        accessor: "prevCountLoader",
      },
      {
        Header: "loader",
        accessor: "loader",
      },
      {
        Header: "vizApp-loader",
        accessor: "vizAppLoader",
      },
      {
        Header: "Reports consumed (previous)",
        accessor: "previousCount",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return values.previousCount ? (
            <div
              style={{
                borderRadius: "5px",
                border: "none",
                width: "100%",
                padding: "5px",
                textAlign: "center",
              }}
            >
              <span>{values.previousCount}</span>
            </div>
          ) : (
            <button
              className="btn btn-primary w-100"
              style={{
                fontSize: isMobile ? "13px" : "",
                padding: isMobile ? "4px" : "",
              }}
              onClick={async () => {
                await getClientCounts(values, "smartreport", true);
              }}
            >
              {values.prevCountLoader ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                "Get Count"
              )}
            </button>
          );
        },
      },

      {
        Header: "Reports consumed",
        accessor: "count",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return values.count ? (
            <div
              style={{
                backgroundColor:
                  parseFloat(values.count) >
                  parseFloat(values.reportsSubscribed)
                    ? "#FFDBD7"
                    : "#D1FFE9",
                borderRadius: "5px",
                border: "none",
                width: "100%",
                padding: "5px",
                textAlign: "center",
              }}
            >
              <span
                style={{
                  color:
                    parseFloat(values.count) >
                    parseFloat(values.reportsSubscribed)
                      ? "#E3695E"
                      : "#2DAC6E",
                }}
              >
                {values.count}
              </span>
            </div>
          ) : (
            <button
              className="btn btn-primary w-100"
              style={{
                fontSize: isMobile ? "13px" : "",
                padding: isMobile ? "4px" : "",
              }}
              onClick={async () => {
                await getClientCounts(values, "smartreport");
              }}
            >
              {values.loader ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                "Get Count"
              )}
            </button>
          );
        },
        showMobile: true,
      },
      {
        Header: "Vizapp Count",
        accessor: "vizAppCount",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return values.vizAppCount ? (
            <div
              style={{
                borderRadius: "5px",
                border: "none",
                width: "100%",
                padding: "5px",
                textAlign: "center",
              }}
            >
              {values.vizAppCount}
            </div>
          ) : (
            <button
              className="btn btn-primary w-100"
              onClick={async () => {
                await getClientCounts(values, "vizapp");
              }}
            >
              {values.vizAppLoader ? (
                <Spinner animation="border" variant="white" size="sm" />
              ) : (
                "Get Count"
              )}
            </button>
          );
        },
      },
      {
        Header: "Subscription Start Date",
        accessor: "startDate",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          return values.startDate.split(" ")[0];
        },
      },
      {
        Header: "Subscription End Date",
        accessor: "endDate",
        headerClassName: "alignment",
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;
          //   console.log('values', isValidSubscriptionEndDate(values.endDate));
          if (isValidSubscriptionEndDate(values.endDate)) {
            return (
              <div
                style={{
                  backgroundColor: "#D1FFE9",
                  borderRadius: "5px",
                  border: "none",
                  width: "100%",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "#2DAC6E" }}>
                  {values.endDate.split(" ")[0]}
                </span>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  backgroundColor: "#FFDBD7",
                  borderRadius: "5px",
                  border: "none",
                  width: "100%",
                  padding: "5px",
                  textAlign: "center",
                }}
              >
                <span style={{ color: "#E3695E" }}>
                  {values.endDate.split(" ")[0]}
                </span>
              </div>
            );
          }
        },
      },
      {
        Header: "Subscription Status",
        accessor: "subscriptionStatus",
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;

          if (values.subscriptionStatus === "live") {
            return (
              <div
                style={{
                  backgroundColor: "#D1FFE9",
                  borderRadius: "5px",
                  border: "none",
                  width: isMobile ? "80%" : "100%",
                  padding: "5px",
                  textAlign: "center",
                  margin: "auto",
                  fontSize: isMobile ? "14px" : "16px",
                }}
              >
                <span style={{ color: "#2DAC6E", textTransform: "Capitalize" }}>
                  {values.subscriptionStatus}
                </span>
              </div>
            );
          } else {
            return (
              <div
                style={{
                  backgroundColor: "#FFDBD7",
                  borderRadius: "5px",
                  border: "none",
                  width: isMobile ? "80%" : "100%",
                  padding: "3px",
                  textAlign: "center",
                  margin: "auto",
                  fontSize: isMobile ? "14px" : "16px",
                }}
              >
                <span style={{ color: "#E3695E", textTransform: "Capitalize" }}>
                  {values.subscriptionStatus}
                </span>
              </div>
            );
          }
        },
        showMobile: true,
      },
      {
        Header: "Edit",
        accessor: "Edit",
        disableSortBy: true,
        Cell: (cell) => {
          const { row } = cell;
          const { values } = row;

          return (
            <button
              className="btn btn-primary"
              style={{
                fontSize: isMobile ? "13px" : "",
                padding: isMobile ? "4px 8px" : "",
              }}
              onClick={() => {
                localStorage.setItem(
                  "choosenClient",
                  JSON.stringify({ org: values.org, center: values.center })
                );
                handleShow();
              }}

              //   state={{ from: original }}
            >
              Edit
            </button>
          );
        },
        showMobile: true,
      },
    ],
    [isMobile]
  );

  return (
    <>
      {pageLoader ? (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner animation="border" />
        </div>
      ) : (
        <>
          <div className="d-flex custom-container">
            <p className="custom-margin pl-2">
              Total Clients : {dataForTables.length}
            </p>
            <p className="mx-2 custom-margin">
              Total Live Clients :{" "}
              {
                dataForTables.filter(
                  (each) => each.subscriptionStatus === "live"
                ).length
              }
            </p>
            <p className="mx-2 custom-margin">
              Total Inactive Clients :{" "}
              {
                dataForTables.filter(
                  (each) => each.subscriptionStatus === "inactive"
                ).length
              }
            </p>
            <p className="mx-2 custom-margin">
              Total On Hold Clients :{" "}
              {
                dataForTables.filter(
                  (each) => each.subscriptionStatus === "on hold"
                ).length
              }
            </p>
          </div>

          <Styles>
            <Table columns={columns} data={dataForTables} />
          </Styles>

          {/* Modal For updating Client Data  */}
          <div>
            <>
              <Modal show={modalOpen} centered size="xl">
                <Modal.Body style={{ textAlign: "center" }}>
                  {/* <Spinner animation='border' size='sm' /> Loading Data ... */}
                  <div className="d-flex w-100 justify-content-end">
                    <button
                      type="button"
                      title="close modal"
                      style={{
                        height: "30px",
                        width: "30px",
                        borderRadius: "40px",
                        padding: "2px",
                      }}
                      className="mb-1 btn btn-danger"
                      aria-label="Close"
                      onClick={() => handleClose()}
                    >
                      X
                    </button>
                  </div>

                  {modalOpen && <Subscription />}
                </Modal.Body>
              </Modal>
            </>
          </div>
        </>
      )}
    </>
  );
};

export default ClientsTableView;
