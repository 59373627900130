import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Spinner from "react-bootstrap/Spinner";
import { useAlert } from "react-alert";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import axios from "axios";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";

const Subscription = () => {
  const alert = useAlert();
  const theme = useTheme();

  const [userId, setUserId] = useState("");
  const [clientId, setClientId] = useState("");
  const [org, setOrg] = useState("");
  const [center, setCenter] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [reportsSubscribed, setReportsSubscribed] = useState("");
  const [subscriptionStatus, setSubscriptionStatus] = useState("");

  const [mailID, setMailId] = useState("");
  const [dashboardAccess, setDashboardAccess] = useState("");
  const [portalAccess, setPortalAccess] = useState("");
  const [smartReportAccess, setsmartReportAccess] = useState("");
  const [bodySummary, setBodySummary] = useState("");
  const [reportSummary, setReportSummary] = useState("");
  const [addtionalTestRec, setaddtionalTestRec] = useState("");
  const [reportType, setReportType] = useState("");

  const [copyDashboardAccess, setCopyDashboardAccess] = useState("");
  const [copyportalAccess, setCopyPortalAccess] = useState("");
  const [copysmartReportAccess, setCopysmartReportAccess] = useState("");
  const [copybodySummary, setCopyBodySummary] = useState("");
  const [copyreportSummary, setCopyReportSummary] = useState("");
  const [copyaddtionalTestRec, setCopyaddtionalTestRec] = useState("");
  const [copyreportType, setCopyReportType] = useState("");
  const [displaySubscription, setDisplaySubscription] = useState("");
  const [zohobooksData, setZohobooksData] = useState({});
  const [lamdbaDetails, setLambdaDetails] = useState({ name: "", link: "" });
  const [integrationType, setIntegrationType] = useState("");

  // const [selectedUserId, setSelectedUserId] = useState("")
  const [fetchOrgStatus, setFetchOrgStatus] = useState(true);
  const [recordOfClientData, setRecordOfClientData] = useState([]);
  const [enableSpinner, setEnableSpinner] = useState(false);
  const [errorWhileFetchingDetails, setErrorWhileFetchingDetails] =
    useState("");
  const [holdOrgData, setHoldOrgData] = useState("");
  const [copyStartDate, setCopyStartDate] = useState("");
  const [copyEndDate, setCopyEndDate] = useState("");
  const [correctJsonFormat, setCorrectJsonFormat] = useState("");

  const [rerenderReact, setRerenderReact] = useState(false);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [password, setPassword] = useState("");

  useEffect(() => {
    let getLoginDetails = localStorage.getItem("loginDetails");

    if (getLoginDetails) {
      let theData = JSON.parse(localStorage.getItem("loginDetails"));
      console.log("getting:", theData);
      if (theData.role === "admin") {
        fetchOrgForUpdation();
      } else {
        window.location.replace("/");
      }
    }
  }, []);

  const submitHandler = (e) => {
    e.preventDefault();

    // handling date format (attaching current time with the date)
    // var currentTime = new Date();
    // var currentOffset = currentTime.getTimezoneOffset();

    // var ISTOffset = 330;   // IST offset UTC +5:30
    // var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);

    // var hoursIST = ISTTime.getHours()
    // var minutesIST = ISTTime.getMinutes()

    // let theLength = hoursIST.toString().length
    // let lastString = "59.000"

    // if (theLength === 1) {
    //     hoursIST = "0" + hoursIST
    // }

    // let format = `${hoursIST}:${minutesIST}:${lastString}`

    // console.log(format)

    let objJson = {
      org: org,
      center: center,
      userId: userId,
      clientId: clientId,
      startDate:
        startDate === copyStartDate ? startDate : `${startDate} 00:00:00.000`,
      endDate: endDate === copyEndDate ? endDate : `${endDate} 23:59:59.000`,
      reportsSubscribed: reportsSubscribed,
      subscriptionStatus: subscriptionStatus,
      reportSubscription: {
        reportType: reportType,
        addOns: {
          bodySummary: bodySummary,
          reportSummary: reportSummary,
          additionalTestRecommendation: addtionalTestRec,
        },
      },
      notifications: {
        mailIds: mailID.includes(",") ? mailID.split(",") : [mailID],
      },
      accessTo: {
        dashBoard: dashboardAccess,
        portal: portalAccess,
        smartReport: smartReportAccess,
      },
      subscription: displaySubscription,
      reportAddons: selectedAddons,
      zohobooks: zohobooksData,
      lamdbaDetails: lamdbaDetails,
      integrationType: integrationType,
    };

    console.log("Sending:\n", objJson);
    setCorrectJsonFormat(objJson);
  };

  var selectedUserId = "";

  const fetchOrgForUpdation = async () => {
    const clientDataStatus = localStorage.getItem("loginDetails");
    const clientChoosen = JSON.parse(localStorage.getItem("choosenClient"));

    if (clientDataStatus) {
      let clientsData = JSON.parse(clientDataStatus).clientData;
      setRecordOfClientData(clientsData);

      console.log("clientsData here:\n", clientsData, clientChoosen);

      for (let i = 0; i < clientsData.length; i++) {
        let eachObj = clientsData[i];
        if (
          eachObj.org === clientChoosen.org &&
          eachObj.center === clientChoosen.center
        ) {
          console.log("required obj:", eachObj);
          selectedUserId = eachObj.userId;
          await handleSubscriptionDetails();
          await setFetchOrgStatus(true);
        } else {
          console.log("nothing found");
          await setFetchOrgStatus(false);
        }
      }
    }
  };

  const handleSubscriptionDetails = async () => {
    setEnableSpinner(true);
    let detailsEndpoint = `https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/subscription/status?userId=${selectedUserId}`;

    var config = {
      method: "GET",
      url: detailsEndpoint,
      // url: "",
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      console.log("fetching ====>", detailsEndpoint);
      const axiosData = await axios(config);
      //   console.log('inside axiosData =>\n', axiosData);
      await setHoldOrgData(axiosData.data.data);

      // updating states values
      await setUserId(axiosData.data.data.userId);
      await setClientId(axiosData.data.data.clientId);
      await setOrg(axiosData.data.data.org);
      await setCenter(axiosData.data.data.center);
      await setStartDate(axiosData.data.data.startDate);
      await setEndDate(axiosData.data.data.endDate);
      await setReportsSubscribed(axiosData.data.data.reportsSubscribed);
      await setSubscriptionStatus(axiosData.data.data.subscriptionStatus);
      await setPassword(axiosData.data.data.password);

      await setCopyStartDate(axiosData.data.data.startDate);
      await setCopyEndDate(axiosData.data.data.endDate);

      await setMailId(axiosData.data.data.notifications.mailIds.join(","));
      await setDashboardAccess(axiosData.data.data.accessTo.dashBoard);
      await setCopyDashboardAccess(axiosData.data.data.accessTo.dashBoard);
      await setPortalAccess(axiosData.data.data.accessTo.portal);
      await setCopyPortalAccess(axiosData.data.data.accessTo.portal);
      await setsmartReportAccess(axiosData.data.data.accessTo.smartReport);
      await setCopysmartReportAccess(axiosData.data.data.accessTo.smartReport);
      await setDisplaySubscription(axiosData.data.data.subscription);

      if (axiosData.data.data.reportAddons) {
        await setSelectedAddons(axiosData.data.data.reportAddons);
      }

      if (axiosData.data.data.hasOwnProperty("zohobooks")) {
        setZohobooksData(axiosData.data.data.zohobooks);
      }

      if (axiosData.data.data.lamdbaDetails) {
        setLambdaDetails(axiosData.data.data.lamdbaDetails);
      }
      if (axiosData.data.data.integrationType) {
        setIntegrationType(axiosData.data.data.integrationType);
      }

      if (axiosData.data.data.hasOwnProperty("reportSubscription")) {
        await setReportType(axiosData.data.data.reportSubscription.reportType);
        await setCopyReportType(
          axiosData.data.data.reportSubscription.reportType
        );
        if (axiosData.data.data.reportSubscription.hasOwnProperty("addOns")) {
          await setReportSummary(
            axiosData.data.data.reportSubscription.addOns.reportSummary
          );
          await setCopyReportSummary(
            axiosData.data.data.reportSubscription.addOns.reportSummary
          );
          await setBodySummary(
            axiosData.data.data.reportSubscription.addOns.bodySummary
          );
          await setCopyBodySummary(
            axiosData.data.data.reportSubscription.addOns.bodySummary
          );

          await setaddtionalTestRec(
            axiosData.data.data.reportSubscription.addOns
              .additionalTestRecommendation
          );
          await setCopyaddtionalTestRec(
            axiosData.data.data.reportSubscription.addOns
              .additionalTestRecommendation
          );
        }
      }

      setEnableSpinner(false);
      setFetchOrgStatus(true);
    } catch (error) {
      setEnableSpinner(false);
      setErrorWhileFetchingDetails(`Error: ${error.message}`);
      setTimeout(() => {
        setErrorWhileFetchingDetails("");
      }, 3000);
      console.log(
        "Error while fetching subscription details:\n",
        error.message
      );
    }
  };

  const submitSubscriptionData = async () => {
    var config = {
      method: "POST",
      // url: "",
      url: "https://rnxsohimg1.execute-api.ap-south-1.amazonaws.com/Production/subscription/update",
      headers: {
        "Content-Type": "application/json",
      },
      data: correctJsonFormat,
    };

    try {
      const axiosData = await axios(config);
      console.log("subscription data updated:", axiosData);
      setCorrectJsonFormat("");
      fetchOrgForUpdation();
      // alert.success('Data Updated');
      window.alert("Data Updated");
      // window.location.reload();
      // setRerenderReact(!rerenderReact)
      //
    } catch (error) {
      alert.error("Subscription Failed");
      console.log("Error while updating subscription data:\n", error.message);
    }
  };

  // Select Org Logic
  const userIdSelectionJSX = () => {
    return (
      <>
        {/* <div className='mb-3' style={{ display: errorWhileFetchingDetails === "" ? "none" : "", border: "1px solid #ff4d4d", padding: "12px", borderRadius: "6px" }}>
                    <span style={{ color: "#ff4d4d" }}>{errorWhileFetchingDetails}</span>
                </div>
                <label className='mb-2'><b>{"Select User Id"}</b></label>
                <Form.Select aria-label="Default select example" onChange={(e) => setSelectedUserId(e.target.value)}>
                    <option value="">-- --</option>
                    {recordOfClientData.length > 0 ? recordOfClientData.map((each, idx) => (
                        <option value={each.userId} key={idx}>{each.userId}</option>
                    )) : ""}
                </Form.Select>

                {enableSpinner ?
                    <>
                        <button
                            className='btn btn-info btn-sm my-3'
                        >
                            <Spinner size="sm" animation="border" role="status" /> {"Loading..."}
                        </button>
                    </>
                    :
                    <button
                        onClick={handleSubscriptionDetails}
                        className='btn btn-info btn-sm my-3'
                        disabled={selectedUserId === ""}
                    >
                        {"Get Subscription Details"}
                    </button>
                } */}

        <h3>{"Client Not Registered with dashboard!"}</h3>
      </>
    );
  };

  const reportTypeMap = {
    compact: "Compact",
    dynamic: "Personal",
    advanced: "Total",
  };

  const addonElements = [
    "Cover Page",
    "Body Summary",
    "Report Summary",
    "Test Recommendation",
    "Trend Report",
    "Viz App",
    "Smart Video Report",
    "Marketing Page",
  ];

  const ITEM_HEIGHT = 42;
  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedAddons(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  console.log("selectedAddons", selectedAddons);

  // Form Logic
  const returnFormJsx = () => {
    if (userId === "" && clientId === "") {
      return <>{"Client Not Registered with dashboard!"}</>;
    } else {
      return (
        <>
          <Form onSubmit={submitHandler}>
            <div className="inputFields">
              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"userId"}</b>
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  defaultValue={userId}
                  onChange={(e) => setUserId(e.target.value)}
                  placeholder="userId"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"clientId"}</b>
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  defaultValue={clientId}
                  onChange={(e) => setClientId(e.target.value)}
                  placeholder="clientId"
                />
              </InputGroup>
            </div>
            <div className="inputFields">
              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"org"}</b>
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  defaultValue={org}
                  onChange={(e) => setOrg(e.target.value)}
                  placeholder="org"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"center"}</b>
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  defaultValue={center}
                  onChange={(e) => setCenter(e.target.value)}
                  placeholder="center"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"Password"}</b>
                </InputGroup.Text>
                <Form.Control
                  disabled
                  type="text"
                  defaultValue={password}
                  placeholder="password"
                />
              </InputGroup>
            </div>
            <div className="inputFields">
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon16"
                >
                  {`DashBoard Access ${
                    copyDashboardAccess !== ""
                      ? copyDashboardAccess === true
                        ? "(Yes)"
                        : "(No)"
                      : ""
                  }`}
                </InputGroup.Text>
                <Form.Select
                  aria-label="DashBoard Access"
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setDashboardAccess(true);
                    } else {
                      setDashboardAccess(false);
                    }
                  }}
                >
                  {copyDashboardAccess === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}
                  <option value={true} selected={dashboardAccess === true}>
                    {"YES"}
                  </option>
                  <option value={false} selected={dashboardAccess === false}>
                    {"No"}
                  </option>
                </Form.Select>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon17"
                >
                  {`Portal Access  ${
                    copyportalAccess !== ""
                      ? copyportalAccess === true
                        ? "(Yes)"
                        : "(No)"
                      : ""
                  }`}
                </InputGroup.Text>
                <Form.Select
                  aria-label="Portal Access"
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setPortalAccess(true);
                    } else {
                      setPortalAccess(false);
                    }
                  }}
                >
                  {copyportalAccess === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}
                  <option value={true} selected={portalAccess === true}>
                    {"YES"}
                  </option>
                  <option value={false} selected={portalAccess === false}>
                    {"No"}
                  </option>
                </Form.Select>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon18"
                >
                  {`Smart Report Access ${
                    copysmartReportAccess !== ""
                      ? copysmartReportAccess === true
                        ? "(Yes)"
                        : "(No)"
                      : ""
                  }`}
                </InputGroup.Text>
                <Form.Select
                  aria-label="Smart Report Access"
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setsmartReportAccess(true);
                    } else {
                      setsmartReportAccess(false);
                    }
                  }}
                >
                  {copysmartReportAccess === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}
                  <option value={true} selected={smartReportAccess === true}>
                    {"YES"}
                  </option>
                  <option value={false} selected={smartReportAccess === false}>
                    {"No"}
                  </option>
                </Form.Select>
              </InputGroup>
            </div>
            <div className="inputFields">
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon19"
                >
                  {`Body Summary ${
                    copybodySummary !== ""
                      ? copybodySummary === true
                        ? "(Yes)"
                        : "(No)"
                      : "(NA)"
                  }`}
                </InputGroup.Text>
                <Form.Select
                  aria-label="Body Summary"
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setBodySummary(true);
                    } else {
                      setBodySummary(false);
                    }
                  }}
                >
                  {copybodySummary === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}
                  <option value={true} selected={bodySummary === true}>
                    {"YES"}
                  </option>
                  <option value={false} selected={bodySummary === false}>
                    {"No"}
                  </option>
                </Form.Select>
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon20"
                >
                  {`Report Summary ${
                    copyreportSummary !== ""
                      ? copyreportSummary === true
                        ? "(Yes)"
                        : "(No)"
                      : "(NA)"
                  }`}
                </InputGroup.Text>
                <Form.Select
                  aria-label="Report Summary"
                  onChange={(e) => {
                    if (e.target.value === "true") {
                      setReportSummary(true);
                    } else {
                      setReportSummary(false);
                    }
                  }}
                >
                  {copyreportSummary === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}
                  <option value={true} selected={reportSummary === true}>
                    {"YES"}
                  </option>
                  <option value={false} selected={reportSummary === false}>
                    {"No"}
                  </option>
                </Form.Select>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon21"
                >
                  {`Test Recommendation ${
                    copyaddtionalTestRec !== ""
                      ? copyaddtionalTestRec === true
                        ? "(Yes)"
                        : "(No)"
                      : "(NA)"
                  }`}
                </InputGroup.Text>
                <Form.Select
                  id="basic-addon21"
                  aria-label="basic-addon21"
                  onChange={(e) => {
                    console.log(e.target.value, Boolean(e.target.value));
                    if (e.target.value === "true") {
                      setaddtionalTestRec(true);
                    } else {
                      setaddtionalTestRec(false);
                    }
                  }}
                >
                  {copyaddtionalTestRec === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}
                  <option value={true} selected={addtionalTestRec === true}>
                    {"YES"}
                  </option>
                  <option value={false} selected={addtionalTestRec === false}>
                    {"No"}
                  </option>
                </Form.Select>
              </InputGroup>
            </div>
            <div className="inputFields">
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon22"
                  title={"Enter Multiple mail ID's seperated by comma"}
                >
                  {"Notification Mail Id"}
                </InputGroup.Text>
                <Form.Control
                  // required
                  onChange={(e) => {
                    setMailId(e.target.value);
                  }}
                  defaultValue={mailID}
                  placeholder="Mail ID"
                  aria-label="Mail ID"
                  aria-describedby="basic-addon22"
                />
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon22"
                >
                  {`Report Type ${
                    copyreportType !== ""
                      ? `(${reportTypeMap[copyreportType]})`
                      : "(NA)"
                  }`}
                </InputGroup.Text>
                <Form.Select
                  aria-label="Report Type"
                  onChange={(e) => {
                    setReportType(e.target.value);
                  }}
                >
                  <option value={""} disabled selected>
                    {"Select Report Type"}
                  </option>
                  <option value={"dynamic"} selected={reportType === "dynamic"}>
                    {"Personal"}
                  </option>
                  <option
                    value={"advanced"}
                    selected={reportType === "advanced"}
                  >
                    {"Total"}
                  </option>
                  <option value={"compact"} selected={reportType === "compact"}>
                    {"Compact"}
                  </option>
                </Form.Select>
              </InputGroup>
            </div>
            {/* add ons  */}
            <div>
              <Stack direction="row" alignItems="center" mb={2}>
                {/* <Typography sx={{border:""}}>Add Ons</Typography> */}
                <InputGroup.Text
                  style={{ fontWeight: "bold", alignSelf: "stretch" }}
                  id="basic-addon22"
                  title={"Enter Multiple mail ID's seperated by comma"}
                >
                  Report Add-ons
                </InputGroup.Text>
                <FormControl
                  size="small"
                  sx={{ flexGrow: "1", minWidth: "300px" }}
                >
                  {/* <InputLabel id="demo-multiple-chip-label"></InputLabel> */}
                  <Select
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    multiple
                    value={selectedAddons}
                    onChange={handleChange}
                    input={<OutlinedInput id="select-multiple-chip" label="" />}
                    renderValue={(selected) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {addonElements.map((name) => (
                      <MenuItem
                        key={name}
                        value={name}
                        style={getStyles(name, selectedAddons, theme)}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </div>
            {/* add ons  */}
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon7">
                {"Subscription - Display"}
              </InputGroup.Text>
              <Form.Control
                required
                defaultValue={displaySubscription}
                onChange={(e) => {
                  setDisplaySubscription(e.target.value);
                }}
                placeholder="compact/dynamic/advanced"
                aria-label="subscription"
                aria-describedby="basic-addon7"
              />
            </InputGroup>
            <InputGroup className="mb-3 custom-Date-mobile">
              <InputGroup.Text id="inputGroup-sizing-default">
                <b>{"startDate"}</b> <span className="mx-1"></span> <b>{"("}</b>
                {copyStartDate} <b>{")"}</b>
              </InputGroup.Text>
              <Form.Control
                type="date"
                defaultValue={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="startDate"
                className="calander-mobile"
              />
            </InputGroup>
            <InputGroup className="mb-3 custom-Date-mobile">
              <InputGroup.Text id="inputGroup-sizing-default">
                <b>{"endDate"}</b> <span className="mx-1"></span> <b>{"("}</b>
                {copyEndDate} <b>{")"}</b>
              </InputGroup.Text>
              <Form.Control
                type="date"
                defaultValue={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                placeholder="endDate"
                className="calander-mobile"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text style={{ fontWeight: "bold" }} id="basic-addon7">
                {"Zohobooks - CustomerId"}
              </InputGroup.Text>
              <Form.Control
                defaultValue={zohobooksData?.customerId || ""}
                onChange={(e) => {
                  setZohobooksData({
                    ...zohobooksData,
                    customerId: e.target.value,
                  });
                }}
                placeholder="Customer ID"
                aria-label="subscription"
                aria-describedby="basic-addon7"
              />
            </InputGroup>
            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default">
                <b>{"reportsSubscribed"}</b>
              </InputGroup.Text>
              <Form.Control
                type="number"
                defaultValue={reportsSubscribed}
                onChange={(e) => setReportsSubscribed(e.target.value)}
                placeholder="reportsSubscribed"
              />
            </InputGroup>
            {/* lambda name & link  */}
            <div className="inputFields">
              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"Lambda Name"}</b>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  defaultValue={lamdbaDetails.name ? lamdbaDetails.name : ""}
                  onChange={(e) =>
                    setLambdaDetails((prevState) => ({
                      ...prevState,
                      name: e.target.value,
                    }))
                  }
                  placeholder="Enter Lambda Name"
                />
              </InputGroup>

              <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default">
                  <b>{"Lambda Link"}</b>
                </InputGroup.Text>
                <Form.Control
                  type="text"
                  defaultValue={lamdbaDetails.link ? lamdbaDetails.link : ""}
                  onChange={(e) =>
                    setLambdaDetails((prevState) => ({
                      ...prevState,
                      link: e.target.value,
                    }))
                  }
                  placeholder="Enter Lambda Link"
                />
              </InputGroup>
            </div>
            {/* lambda name & link  */}

            {/* integration type */}
            <div>
              <InputGroup className="mb-3">
                <InputGroup.Text
                  style={{ fontWeight: "bold" }}
                  id="basic-addon22"
                >
                  Integration Type
                </InputGroup.Text>
                <Form.Select
                  aria-label="Report Type"
                  onChange={(e) => {
                    setIntegrationType(e.target.value);
                  }}
                >
                  {integrationType === "" && (
                    <option value={""} disabled selected>
                      {"Select Option"}
                    </option>
                  )}

                  <option
                    value={"modelA"}
                    selected={integrationType === "modelA"}
                  >
                    Model A
                  </option>
                  <option
                    value={"modelB"}
                    selected={integrationType === "modelB"}
                  >
                    Model B
                  </option>
                </Form.Select>
              </InputGroup>
            </div>
            {/* integration type */}
            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default">
                <b>{"subscriptionStatus"}</b>
              </InputGroup.Text>

              <Form.Select
                aria-label="Default select example"
                onChange={(e) => setSubscriptionStatus(e.target.value)}
              >
                <option value="live" selected={subscriptionStatus === "live"}>
                  {"LIVE"}
                </option>
                <option
                  value="on hold"
                  selected={subscriptionStatus === "on hold"}
                >
                  {"HOLD"}
                </option>
                <option
                  value="inactive"
                  selected={subscriptionStatus === "inactive"}
                >
                  {"INACTIVE"}
                </option>
              </Form.Select>

              {/* <Form.Control
                                type="text"
                                defaultValue={subscriptionStatus}
                                onChange={(e) => setSubscriptionStatus(e.target.value)}
                                placeholder="subscriptionStatus"
                            /> */}
            </InputGroup>
            <Button variant="primary btn-sm" type="submit">
              Save Changes
            </Button>
            <Button
              onClick={submitSubscriptionData}
              className="mx-3"
              variant="success btn-sm"
              disabled={correctJsonFormat === ""}
            >
              Update Subscription Data
            </Button>
          </Form>
        </>
      );
    }
  };

  // react alert template options
  const options = {
    timeout: 5000,
    position: "bottom left",
  };

  return (
    <>
      <Provider template={AlertTemplate} {...options}>
        {/* {fetchOrgStatus ? returnFormJsx() : userIdSelectionJSX()} */}
        {returnFormJsx()}
      </Provider>
    </>
  );
};

export default Subscription;
