import React, { useEffect, useState } from "react";
import { Card, Spinner } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
import { Line } from "rc-progress";
import "../../css/topFivePackageCss.css";
import axios from "axios";

// let dataLoaded = false;
const ClientDistributonSection = ({ productType }) => {
  const [pieDataCurrentMonth, setPieDataCurrentMonth] = useState(true);
  const [fetchedData, setFetchedData] = useState("");
  const [reRenderComponent, setReRenderComponent] = useState(false);
  const [loginData, setLoginData] = useState("");
  const [pieDataPastMonth, setPieDataPastMonth] = useState(false);
  const [clientPreference, setClientPreference] = useState("");
  const [loader, setLoader] = useState(true);

  window.addEventListener("storage", (e) => {
    // console.log("something got changed.")
    setReRenderComponent(!reRenderComponent);
  });

  const [renderJsxStatus, setRenderJsxStatus] = useState(false);

  let top5packagesPercentages = [];
  let totalOfAll5Package = [];
  let top5packages = [];

  // fetch data from local storage
  useEffect(() => {
    setPieDataCurrentMonth(true);
    setPieDataPastMonth(false);

    let homepageData = JSON.parse(localStorage.getItem("homepageData"));
    let loginInfo = JSON.parse(localStorage.getItem("loginDetails"));
    let clientPreferenceInfo = JSON.parse(
      localStorage.getItem("clientPreference")
    );

    if (true) {
      //   setFetchedData(homepageData);
      setRenderJsxStatus(true);
    }
    if (loginInfo) {
      setLoginData(loginInfo);
    }
    if (clientPreferenceInfo) {
      setClientPreference(clientPreferenceInfo);
    }
  }, [reRenderComponent]);

  const formattedStartDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 00:00:00.000`;
    return requiredTime;
  };

  const formattedEndDate = (date) => {
    const dateInFormat = new Date(date);
    const requiredTime = `${dateInFormat.getFullYear()}-${
      dateInFormat.getMonth() >= 9
        ? dateInFormat.getMonth() + 1
        : `0${dateInFormat.getMonth() + 1}`
    }-${
      dateInFormat.getDate() > 10
        ? dateInFormat.getDate()
        : `0${dateInFormat.getDate()}`
    } 23:59:59.999`;
    return requiredTime;
  };

  const getCurrentMonthStartEndDates = () => {
    let todaysDate = new Date();
    let startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      1,
      0,
      0,
      0,
      0
    );

    return {
      startDate: formattedStartDate(startDate),
      endDate: formattedEndDate(todaysDate),
    };
  };

  const getPreviousMonthStartEndDates = () => {
    let todaysDate = new Date();
    const startDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth() - 1,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      todaysDate.getFullYear(),
      todaysDate.getMonth(),
      0,
      23,
      59,
      59,
      0
    );
    return {
      startDate: formattedStartDate(startDate),
      endDate: formattedEndDate(endDate),
    };
  };

  const getClientWiseCounts = async (startDate, endDate) => {
    setLoader(true);
    const idToken = localStorage.getItem("idTokenBilling");
    const configBody = {
      headers: {
        authorization: idToken,
      },
      method: "get",
      url: `https://api.niroggyan.com/billing/${productType}/counts?query_on=org&query_by=range&start=${startDate}&end=${endDate}`,
      validateStatus: function (status) {
        return (status >= 200) & (status < 510);
      },
    };
    const response = await axios(configBody);
    setLoader(false);
    return response;
  };

  const previousMonthClientWIseCounts = async () => {
    let startDate = getPreviousMonthStartEndDates().startDate;
    let endDate = getPreviousMonthStartEndDates().endDate;
    (async function () {
      const response = await getClientWiseCounts(startDate, endDate);
      console.log("response from ClientDistribution", response);
      setFetchedData(response.data.data);
    })();
  };

  const currentMonthClientWiseCounts = async () => {
    let startDate = getCurrentMonthStartEndDates().startDate;
    let endDate = getCurrentMonthStartEndDates().endDate;
    (async function () {
      const response = await getClientWiseCounts(startDate, endDate);
      console.log("response from ClientDistribution", response);
      setFetchedData(response.data.data);
    })();
  };

  useEffect(() => {
    const startDate = getCurrentMonthStartEndDates().startDate;
    const endDate = getCurrentMonthStartEndDates().endDate;

    // dataLoaded &&
    (async function () {
      const response = await getClientWiseCounts(startDate, endDate);
      console.log("response from ClientDistribution", response);
      setFetchedData(response.data.data);
    })();
    // dataLoaded = true;
  }, [productType]);

  // FOR TESTING
  //let dataToFetch = dummyData[0]

  // ACTUAL DATA
  //   let dataToFetch = fetchedData ? fetchedData.data : '';

  // calculating top 5 packages (based on current and previous month)

  // for current month (top 5 package)

  top5packagesPercentages = [];

  // sum of all top 5 package counts
  totalOfAll5Package = fetchedData
    ? fetchedData.values.reduce(
        (partialSum, a) => parseInt(partialSum) + parseInt(a),
        0
      )
    : [];

  top5packages = fetchedData ? fetchedData.keys : "";

  for (let i = 0; i < top5packages.length; i++) {
    //console.log("here: ", top5packages[i])
    let reportCountOfPackage = fetchedData.values[i];
    let percentageOfReportCount = Math.round(
      (reportCountOfPackage / totalOfAll5Package) * 100
    );
    top5packagesPercentages.push(percentageOfReportCount);
  }

  // sorting in packages in descending order
  top5packagesPercentages.sort(function (a, b) {
    return b - a;
  });

  // for past month (top 5 package)
  //   else if (pieDataPastMonth && !pieDataCurrentMonth) {
  //     top5packagesPercentages = [];

  //     // sum of all top 5 package counts
  //     totalOfAll5Package = dataToFetch
  //       ? dataToFetch.past_months.center_wise.values.reduce(
  //           (partialSum, a) => parseInt(partialSum) + parseInt(a),
  //           0
  //         )
  //       : '';

  //     top5packages = dataToFetch ? dataToFetch.past_months.center_wise.keys : '';

  //     for (let i = 0; i < top5packages.length; i++) {
  //       //console.log("here: ", top5packages[i])
  //       let reportCountOfPackage = dataToFetch.past_months.center_wise.values[i];
  //       let percentageOfReportCount = Math.round(
  //         (reportCountOfPackage / totalOfAll5Package) * 100
  //       );
  //       top5packagesPercentages.push(percentageOfReportCount);
  //     }

  //     // sorting in packages in descending order
  //     top5packagesPercentages.sort(function (a, b) {
  //       return b - a;
  //     });
  //   }

  // for previous month (top 5 package)
  //   else if (!pieDataCurrentMonth && !pieDataPastMonth) {
  //     top5packagesPercentages = [];

  //     // sum of all top 5 package counts
  //     totalOfAll5Package = dataToFetch.previous_month.center_wise.values.reduce(
  //       (partialSum, a) => parseInt(partialSum) + parseInt(a),
  //       0
  //     );

  //     top5packages = dataToFetch.previous_month.center_wise.keys;

  //     for (let i = 0; i < top5packages.length; i++) {
  //       //console.log("here: ", top5packages[i])
  //       let reportCountOfPackage =
  //         dataToFetch.previous_month.center_wise.values[i];
  //       let percentageOfReportCount = Math.round(
  //         (reportCountOfPackage / totalOfAll5Package) * 100
  //       );
  //       top5packagesPercentages.push(percentageOfReportCount);
  //     }

  //     // sorting in packages in descending order
  //     top5packagesPercentages.sort(function (a, b) {
  //       return b - a;
  //     });
  //   }

  //console.log("top5packagesPercentages: ", top5packagesPercentages)

  let colorsForTop5Packages = [
    "#ff6384",
    "#36a2eb",
    "#ffcd56",
    "#ffe0e6",
    "#d7ecfb",
  ];

  //console.log("top5packagesPercentages: ", top5packagesPercentages)

  let allTheData = {
    series: top5packagesPercentages,
    options: {
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
      },
      dataLabels: {
        enabled: true,
        // formatter: function (val) {
        //     return val + "%"
        // },
        formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex] + "%";
        },
      },
      colors: colorsForTop5Packages,
      legend: {
        show: false,
      },
      chart: {
        width: "380px",
        type: "pie",
      },
      labels: top5packages,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  const renderJSX = () => {
    return (
      <div className="mb-4">
        <Card>
          <Card.Body>
            <Card.Title className="topFivePackageButtonsCss">
              <div>{"Client Distribution"}</div>
              <div className="topFivePackageButtonsMarginsCss">
                <button
                  style={{
                    color:
                      pieDataCurrentMonth && !pieDataPastMonth ? "#fff" : "",
                    backgroundColor:
                      pieDataCurrentMonth && !pieDataPastMonth ? "#3567D6" : "",
                  }}
                  onClick={async () => {
                    setPieDataCurrentMonth(true);
                    setPieDataPastMonth(false);
                    !pieDataCurrentMonth &&
                      (await currentMonthClientWiseCounts());
                  }}
                  className="top5packageButtonCss"
                >
                  <span className="packageCurrentMonthTextCss"></span>
                </button>
                <button
                  style={{
                    color:
                      !pieDataCurrentMonth && !pieDataPastMonth ? "#fff" : "",
                    backgroundColor:
                      !pieDataCurrentMonth && !pieDataPastMonth
                        ? "#3567D6"
                        : "",
                  }}
                  onClick={async () => {
                    setPieDataCurrentMonth(false);
                    setPieDataPastMonth(false);
                    await previousMonthClientWIseCounts();
                  }}
                  className="top5packageButtonCss"
                >
                  <span className="packagePreviousMonthTextCss"></span>
                </button>
                <button
                  style={{
                    display:
                      (loginData && loginData.role === "admin") ||
                      (loginData && loginData.role === "LIS")
                        ? ""
                        : "none",
                    color:
                      pieDataPastMonth && !pieDataCurrentMonth ? "#fff" : "",
                    backgroundColor:
                      pieDataPastMonth && !pieDataCurrentMonth ? "#3567D6" : "",
                  }}
                  onClick={() => {
                    setPieDataCurrentMonth(false);
                    setPieDataPastMonth(true);
                  }}
                  className="top5packageButtonCss"
                >
                  <span className="packagePastMonthTextCss"></span>
                </button>
              </div>
            </Card.Title>

            <div className="pieContentsResolutionCss">
              <div
                className={
                  top5packagesPercentages.length === 0
                    ? ""
                    : "pieLeftContentCss"
                }
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card.Subtitle>
                  {top5packagesPercentages.length === 0 && !loader ? (
                    <span style={{ color: "#7787bc" }}>
                      <span>No Data to display</span>
                    </span>
                  ) : loader ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    <ReactApexChart
                      options={allTheData.options}
                      series={allTheData.series}
                      type="pie"
                      width={380}
                    />
                  )}
                </Card.Subtitle>
              </div>

              <div className="pieRightContentCssWidth">
                {/* Current Month Count (for pie chart left content) */}
                <span
                  style={{
                    display: !pieDataPastMonth ? "" : "none",
                  }}
                >
                  {fetchedData &&
                    fetchedData.keys.map((each, idx) => (
                      <>
                        <div key={idx} style={{ marginBottom: "2px" }}>
                          <div className="pieRightContentLineHeaderCss">
                            {each} {"("}{" "}
                            {parseInt(fetchedData.values[idx]).toLocaleString(
                              "en-IN"
                            )}{" "}
                            {")"} {""}{" "}
                            {Math.round(
                              (fetchedData.values[idx] / totalOfAll5Package) *
                                100
                            )}
                            {"%"}
                          </div>
                          <Line
                            className="pieRightContentLineCss"
                            percent={Math.round(
                              (fetchedData.values[idx] / totalOfAll5Package) *
                                100
                            )}
                            strokeColor={colorsForTop5Packages[idx]}
                          />
                        </div>
                      </>
                    ))}
                </span>

                {/* Previous Month Count (for pie chart left content) */}
                {/* <span
                  style={{
                    display:
                      !pieDataCurrentMonth && !pieDataPastMonth ? '' : 'none',
                  }}
                >
                  {dataToFetch.previous_month.center_wise.keys.map(
                    (each, idx) => (
                      <>
                        <div key={idx} style={{ marginBottom: '2px' }}>
                          <div className='pieRightContentLineHeaderCss'>
                            {each} {'('}{' '}
                            {dataToFetch.previous_month.center_wise.values[idx]}{' '}
                            {')'} {''}{' '}
                            {Math.round(
                              (dataToFetch.previous_month.center_wise.values[
                                idx
                              ] /
                                totalOfAll5Package) *
                                100
                            )}
                            {'%'}
                          </div>
                          <div>
                            <Line
                              className='pieRightContentLineCss'
                              percent={Math.round(
                                (dataToFetch.previous_month.center_wise.values[
                                  idx
                                ] /
                                  totalOfAll5Package) *
                                  100
                              )}
                              strokeColor={colorsForTop5Packages[idx]}
                            />
                          </div>
                        </div>
                      </>
                    )
                  )}
                </span> */}

                {/* Past Month Count (for pie chart left content) */}
                {/* <span
                  style={{
                    display:
                      (!pieDataCurrentMonth &&
                        pieDataPastMonth &&
                        loginData.role === 'admin') ||
                      (!pieDataCurrentMonth &&
                        pieDataPastMonth &&
                        loginData.role === 'LIS')
                        ? ''
                        : 'none',
                  }}
                >
                  {(loginData &&
                    loginData.role === 'admin' &&
                    clientPreference === 'none') ||
                  (loginData && loginData.role === 'LIS')
                    ? dataToFetch.past_months.center_wise.keys.map(
                        (each, idx) => (
                          <>
                            <div key={idx} style={{ marginBottom: '2px' }}>
                              <div className='pieRightContentLineHeaderCss'>
                                {each} {'('}{' '}
                                {
                                  dataToFetch.past_months.center_wise.values[
                                    idx
                                  ]
                                }{' '}
                                {')'} {''}{' '}
                                {Math.round(
                                  (dataToFetch.past_months.center_wise.values[
                                    idx
                                  ] /
                                    totalOfAll5Package) *
                                    100
                                )}
                                {'%'}
                              </div>
                              <div>
                                <Line
                                  className='pieRightContentLineCss'
                                  percent={Math.round(
                                    (dataToFetch.past_months.center_wise.values[
                                      idx
                                    ] /
                                      totalOfAll5Package) *
                                      100
                                  )}
                                  strokeColor={colorsForTop5Packages[idx]}
                                />
                              </div>
                            </div>
                          </>
                        )
                      )
                    : ''}
                </span> */}
              </div>
            </div>
            {/* <div className="columnBarFooterItemsCss">
                        <Card.Link className="dayWiseFooterCss">
                            <CalenderSvg />
                            {"Day wise Report Count"}
                        </Card.Link>
                        <Card.Link className="reportsGeneratedFooterCss">
                            <ReportsGeneratedSvgIcon />
                            {"Reports Generated"}
                        </Card.Link>
                    </div> */}
          </Card.Body>
        </Card>
      </div>
    );
  };

  return <span>{renderJsxStatus ? renderJSX() : ""}</span>;
};

export default ClientDistributonSection;
