import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  Outlet,
} from "react-router-dom";
import Dashboard from "./components/Dashboard";
import { Spinner } from "react-bootstrap";
import LoginPage from "./components/LoginPage";
import NavbarComponent from "./components/NavbarComponent";
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import Subscription from "./components/SubscriptionPage/Subscription";
import SignUpLab from "./components/signupClient/SignUpLab";
import SignUpLIS from "./components/signupClient/SignUpLIS";
import SignUpAdmin from "./components/signupClient/SignUpAdmin";
import MaintenancePage from "../src/components/maintenance";
import ClientsTableView from "./components/SubscriptionPage/tableView";
import VizAppDashboard from "./components/DashboardVizapp";

const App = () => {
  // RECORD DATE
  // var datetime =  new Date().toLocaleString().replace(',','')
  // let onlyDate = datetime.split(" ")[0]
  // let onlyTime = datetime.split(" ")[1]

  // console.log("date and time: ", onlyDate, ",",onlyTime)

  // useEffect(() => {
  //   window.addEventListener("beforeunload", () => localStorage.removeItem('loginDetails'));
  // }, []);

  // DETECT CLOSING AND REFRESHING OF WEBPAGE
  // useEffect(() => {
  //   return () => {

  //      window.addEventListener("beforeunload", function(e) {

  //     let confirmationMessage = "o/";

  //     (e || window.event).returnValue = confirmationMessage; //Gecko + IE

  //     console.log("logout !");
  //     localStorage.setItem("myLoginDetails", JSON.stringify(""))
  //     localStorage.removeItem("loginDetails")
  //     localStorage.removeItem("homepageData")

  //     return confirmationMessage; //Webkit, Safari, Chrome

  //   });
  //  }

  // });

  // react alert config
  const options = {
    timeout: 5000,
    // position: positions.BOTTOM_CENTER
  };

  const [loadingStatus, setLoadingStatus] = useState(true);

  const cacheImages = async (imagesToLoad) => {
    const promises = await imagesToLoad.map((src) => {
      return new Promise(function (resolve, reject) {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoadingStatus(false);
  };

  useEffect(() => {
    // loading all the images first
    // const allImages = collectionOfAllLinks

    const allImages = [
      "https://niroggyan.s3.ap-south-1.amazonaws.com/React-Billing-Dashboard-files/WelcomeScreen+Files/welcomeScreenSvg.svg",
    ];

    //console.log("all Links\n", allImages)

    cacheImages(allImages);
  }, []);

  const loginData = JSON.parse(localStorage.getItem("loginDetails"));

  const ProtectedRoutes = () => {
    const loginData = JSON.parse(localStorage.getItem("loginDetails"));
    return loginData ? <Outlet /> : <Navigate to="/login" />;
  };

  return (
    <>
      {loadingStatus ? (
        <Spinner
          style={{ position: "absolute", top: "50%", left: "50%" }}
          id="loadingSpinnerCss"
          animation="border"
          role="status"
        />
      ) : (
        <>
          <NavbarComponent />
          <Provider template={AlertTemplate} {...options}>
            <div className="container-fluid p-3">
              <Router>
                <Routes>
                  <Route element={<ProtectedRoutes />}>
                    <Route
                      exact
                      path="/"
                      element={loginData ? <VizAppDashboard /> : <LoginPage />}
                    />

                    <Route
                      exact
                      path="/subscription"
                      element={<Subscription />}
                    />
                    <Route
                      exact
                      path="/new-billing-dashboard"
                      element={<VizAppDashboard />}
                    />
                    <Route
                      exact
                      path="/new-client/lab"
                      element={<SignUpLab />}
                    />
                    <Route
                      exact
                      path="/new-client/lis"
                      element={<SignUpLIS />}
                    />
                    <Route
                      exact
                      path="/new-client/admin"
                      element={<SignUpAdmin />}
                    />
                    <Route
                      exact
                      path="/maintenance"
                      element={<MaintenancePage />}
                    />
                    <Route
                      exact
                      path="/client-Data"
                      element={<ClientsTableView />}
                    />
                  </Route>
                  <Route exact path="/login" element={<LoginPage />} />
                  {/* <Route path="*" element={<NotFound/>}/> */}
                </Routes>
              </Router>
            </div>
          </Provider>
        </>
      )}
    </>
  );
};

export default App;
