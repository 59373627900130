import {
  Stack,
  LinearProgress,
  Typography,
  Grid,
  Divider,
  IconButton,
  Paper,
  Box,
} from "@mui/material";

import StatusCard from "./StatusCard";
import axios from "axios";
import { useEffect, useState } from "react";
import CreateStep from "./CreateStep";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const OnboardingStatus = () => {
  const idToken = localStorage.getItem("idTokenBilling");
  const loginData = JSON.parse(localStorage.getItem("loginDetails"));
  const clientId = localStorage
    .getItem("clientIdPreference")
    ?.replace(/^"|"$/g, "");

  const [statusData, setStatusData] = useState(null);
  const [isCreatePopupOpen, setCreatePopupOpen] = useState(false);
  const [signUpDate, setSignUpDate] = useState("");

  const handleCreatePopupOpen = () => {
    setCreatePopupOpen(true);
  };

  const handleCreatePopupClose = () => {
    setCreatePopupOpen(false);
  };

  let totalSubsteps = 0;
  let completedSubsteps = 0;

  statusData?.data?.forEach((item) => {
    if (item.substeps && Array.isArray(item.substeps)) {
      totalSubsteps += item.substeps.length;
      completedSubsteps += item.substeps.filter(
        (substep) => substep.is_substep_completed
      ).length;
    }
  });

  function formatDate(inputDate) {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    const parts = inputDate.split("-");
    if (parts.length !== 3) {
      return "Invalid Date";
    }

    const year = parts[0];
    const monthIndex = parseInt(parts[1]) - 1;
    const day = parseInt(parts[2]);

    if (isNaN(day) || isNaN(monthIndex) || isNaN(year)) {
      return "Invalid Date";
    }

    if (monthIndex < 0 || monthIndex >= months.length) {
      return "Invalid Date";
    }

    return `${day} ${months[monthIndex]} ${year}`;
  }

  async function getStatusAPI() {
    try {
      // axios.defaults.headers["Authorization"] = idToken;
      const response = await axios.get(
        `https://api.niroggyan.com/onboarding/status?clientId=${clientId}`,
        {
          headers: {
            Authorization: `${idToken}`,
          },
        }
      );
      setStatusData(response.data);
      setSignUpDate(response.data?.data[0]?.created_at);
      return response.data;
    } catch (error) {
      return error;
    }
  }

  useEffect(() => {
    async function fetchData() {
      const data = await getStatusAPI();
      console.log(data);
    }
    fetchData();
  }, []);

  const tat = signUpDate
    ? (new Date().getTime() - new Date(signUpDate).getTime()) /
      (24 * 3600 * 1000)
    : "";

  return (
    (statusData?.data || loginData?.role === "admin") &&
    (loginData?.role === "admin" ? clientId : loginData.clientId) && (
      <Stack>
        {(loginData?.role === "admin"
          ? true
          : completedSubsteps !== totalSubsteps) && (
          <>
            <Typography
              style={{
                color: "#1F3058",
                fontSize: "18px",
                fontWeight: "400",
                alignSelf: "center",
              }}
            >
              Onboarding made easy!
            </Typography>
            <Typography
              style={{
                color: "#000",
                fontSize: "32px",
                fontWeight: "600",
                alignSelf: "center",
              }}
            >
              Welcome to NirogGyan
            </Typography>
            {tat ? (
              <Box my={2}>
                <Paper
                  sx={{ display: "inline-block", padding: "8px 20px" }}
                  variant="outlined"
                >
                  TAT: {tat ? `${Math.floor(tat)} days` : ""}
                </Paper>
              </Box>
            ) : (
              ""
            )}
            <Stack justifyContent={"flex-end"} direction={"row"} gap={1}>
              <Typography fontSize={"16px"} fontWeight={400}>
                Completed:
              </Typography>
              <Typography fontSize={"16px"} fontWeight={600}>
                {Math.ceil((completedSubsteps / totalSubsteps) * 100) + "%"}
              </Typography>
            </Stack>
            <LinearProgress
              style={{ background: "#D9D9D9" }}
              variant="determinate"
              value={(completedSubsteps / totalSubsteps) * 100}
              sx={{ borderRadius: "15px", height: "8px", marginBottom: "24px" }}
            />

            <Stack
              direction={"row"}
              justifyContent={"space-around"}
              style={{ marginBottom: "24px" }}
            >
              {statusData?.data?.map((step, i) =>
                step.status === "completed" ? (
                  <Stack key={i + "a"}>
                    {/* <Typography
                      color="#212121"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Step {i + 1} -
                    </Typography> */}
                    <Typography
                      color="#212121"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Date Range:
                    </Typography>
                    <Stack direction={"row"}>
                      <Typography
                        color="#212121"
                        fontSize={"16px"}
                        fontWeight={"400"}
                      >
                        {formatDate(step?.created_at?.split("T")[0]) + " -"}
                      </Typography>
                      <Typography
                        marginLeft={0.5}
                        color="#212121"
                        fontSize={"12px"}
                        fontWeight={"400"}
                      >
                        {formatDate(step?.completed_at?.split("T")[0])}
                      </Typography>
                    </Stack>
                  </Stack>
                ) : (
                  <Stack key={i + "b"}>
                    {/* <Typography
                      color="#212121"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      {" "}
                      Step {i + 1} -
                    </Typography> */}
                    <Typography
                      color="#212121"
                      fontSize={"16px"}
                      fontWeight={"400"}
                    >
                      Expected Deadline:
                    </Typography>
                    <Typography
                      color="#212121"
                      fontSize={"12px"}
                      fontWeight={"400"}
                    >
                      {formatDate(step?.deadline_date?.split("T")[0])}
                    </Typography>
                  </Stack>
                )
              )}
            </Stack>
            <Divider style={{ marginBottom: "24px" }} />
            <Grid alignItems={"flex-end"} container spacing={2}>
              {statusData?.data?.map((step, i) => (
                <Grid item key={i} xs={12} sm={6} md={4} lg={3}>
                  <StatusCard
                    loginData={loginData}
                    stepData={step}
                    index={i}
                    idToken={idToken}
                    getStatusAPI={getStatusAPI}
                  />
                </Grid>
              ))}
              {loginData?.role === "admin" && (
                <Paper
                  style={{
                    alignSelf: "flex-end",
                    marginBottom: "16px",
                    marginLeft: "16px",
                    width: "250px",
                    height: "459px",
                    borderRadius: "12px",
                    border: "1px dashed #C4CAD5",
                  }}
                >
                  <IconButton
                    style={{ marginTop: "205px", marginLeft: "95px" }}
                    onClick={handleCreatePopupOpen}
                  >
                    <AddCircleOutlineOutlinedIcon
                      style={{ fontSize: "3.5rem" }}
                    />
                  </IconButton>
                </Paper>
              )}
            </Grid>
            <CreateStep
              idToken={idToken}
              clientId={clientId}
              open={isCreatePopupOpen}
              onClose={handleCreatePopupClose}
              getStatusAPI={getStatusAPI}
            />
          </>
        )}
      </Stack>
    )
  );
};

export default OnboardingStatus;
